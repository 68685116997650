.filterRow {
  margin-top: 2px;
  padding: 3px 16px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #eaeaea;
  display: flex; }
  .filterRow .searchIcon {
    position: absolute;
    right: 0px;
    padding: 10px 12px;
    color: #000;
    opacity: 0.4;
    font-size: 16px;
    cursor: pointer;
    top: auto; }
  .filterRow .filterMenu {
    display: inline-flex;
    align-items: center;
    vertical-align: middle; }
    .filterRow .filterMenu .filterImage {
      display: inline-block;
      overflow: hidden;
      border-radius: 16px;
      width: 32px;
      height: 32px; }
    .filterRow .filterMenu .filterIcon {
      padding: 8px 10px;
      cursor: pointer;
      height: 32px;
      width: 32px;
      background-color: #1f75b2; }
    .filterRow .filterMenu .filterButton {
      cursor: pointer;
      display: flex;
      align-items: center; }
    .filterRow .filterMenu .dropdown-menu li {
      padding: 8px 0px 8px 16px; }
      .filterRow .filterMenu .dropdown-menu li:hover {
        background-color: #eff7ff; }
    .filterRow .filterMenu .dropdown-menu label {
      cursor: pointer;
      color: #333333;
      padding: 0px;
      margin: 3px 0px;
      font-weight: 400;
      font-size: 12px;
      width: 100%;
      display: flex; }
      .filterRow .filterMenu .dropdown-menu label input[type='checkbox'] {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        margin-top: 0px;
        min-width: 16px; }
  .filterRow .currentFilters {
    display: inline-block;
    padding-top: 9px; }
  .filterRow .enabledFilter {
    display: inline-block;
    border-radius: 16px;
    margin-left: 8px; }
    .filterRow .enabledFilter .filterTitle {
      padding-right: 10px; }
    .filterRow .enabledFilter .removeFilter {
      cursor: pointer;
      color: #717171;
      padding: 0px 7px;
      font-size: 10px;
      position: relative;
      left: 1px;
      top: 1px; }
    .filterRow .enabledFilter .filterSection {
      font-size: 11px;
      display: inline-block;
      background-color: #fafafa;
      border-radius: 16px 0px 0px 16px;
      border: 1px solid #c4c4c4;
      border-right: 0;
      padding: 7px 0px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15); }
      .filterRow .enabledFilter .filterSection .permanentFilter {
        margin-left: 14px; }
    .filterRow .enabledFilter .subfilterSection {
      font-size: 11px;
      display: inline-block;
      cursor: pointer; }
      .filterRow .enabledFilter .subfilterSection .subfilterTitle {
        min-width: 75px;
        background: #fff;
        padding: 7px 20px 7px 10px;
        border: 1px solid #c4c4c4;
        border-radius: 0px 16px 16px 0px;
        border-left: 0;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15); }
      .filterRow .enabledFilter .subfilterSection .caret {
        position: relative !important;
        bottom: 17px !important;
        right: 7px; }
      .filterRow .enabledFilter .subfilterSection .menuHighTen {
        color: #fff;
        background-color: #c83a4b;
        padding: 0px 2px;
        border-radius: 10px; }
      .filterRow .enabledFilter .subfilterSection .menuHigh {
        color: #fff;
        background-color: #c83a4b;
        padding: 1px 5px;
        border-radius: 10px; }
      .filterRow .enabledFilter .subfilterSection .menuMedium {
        color: #000;
        background-color: #fbd241;
        padding: 1px 5px;
        border-radius: 10px; }
      .filterRow .enabledFilter .subfilterSection .menuLow {
        color: #fff;
        background-color: #28c2a6;
        padding: 1px 5px;
        border-radius: 10px; }
      .filterRow .enabledFilter .subfilterSection .dropdown .menuArrow {
        display: flex;
        position: relative;
        bottom: 19px;
        right: 8px; }
      .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu {
        min-width: 225px;
        max-height: 330px;
        overflow-y: scroll; }
        .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu .form-inline {
          padding: 8px; }
        .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu .subfilterSearch {
          width: 100%; }
        .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu li {
          padding: 8px 0px 8px 16px; }
          .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu li:hover {
            background-color: #eff7ff; }
          .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu li .menuHighTen {
            color: #fff;
            background-color: #c83a4b;
            padding: 0px 2px;
            border-radius: 10px; }
          .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu li .menuHigh {
            color: #fff;
            background-color: #c83a4b;
            padding: 0px 5px;
            border-radius: 10px; }
          .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu li .menuMedium {
            color: #000;
            background-color: #fbd241;
            padding: 0px 5px;
            border-radius: 10px; }
          .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu li .menuLow {
            color: #fff;
            background-color: #28c2a6;
            padding: 0px 5px;
            border-radius: 10px; }
        .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu label {
          cursor: pointer;
          color: #333333;
          padding: 0px;
          margin: 3px 0px;
          font-weight: 400;
          font-size: 12px;
          width: 100%;
          display: flex;
          align-items: center; }
          .filterRow .enabledFilter .subfilterSection .dropdown .dropdown-menu label input[type='checkbox'] {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            margin-top: 0px; }
  .filterRow .addFilter {
    font-size: 12px;
    color: #1f75b2;
    padding: 16px 8px;
    display: inline-block; }

.hideCheckbox {
  display: none; }
