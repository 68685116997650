.advancedSaasVisibilityAppListTable .dropdown-menu {
  z-index: 999 !important; }

.advancedSaasVisibilityAppListTable .dropdown-menu-center {
  width: 225px !important; }

.advancedSaasVisibilityAppListTable .ag-row .tagCellRenderer {
  display: none; }

.advancedSaasVisibilityAppListTable .ag-row.ag-row-hover {
  z-index: 1; }
  .advancedSaasVisibilityAppListTable .ag-row.ag-row-hover .tagCellRenderer {
    display: flex; }

.advancedSaasVisibilityAppListTable .appListVisible .ag-root,
.advancedSaasVisibilityAppListTable .appListVisible .ag-root-wrapper,
.advancedSaasVisibilityAppListTable .appListVisible .ag-root-body,
.advancedSaasVisibilityAppListTable .appListVisible .ag-body-viewport,
.advancedSaasVisibilityAppListTable .appListVisible .table-list,
.advancedSaasVisibilityAppListTable .appListVisible .ag-body-viewport-wrapper,
.advancedSaasVisibilityAppListTable .appListVisible .ag-ltr .ag-body-viewport,
.advancedSaasVisibilityAppListTable .appListVisible .ag-center-cols-container,
.advancedSaasVisibilityAppListTable .appListVisible .ag-center-cols-viewport,
.advancedSaasVisibilityAppListTable .appListVisible .ag-center-cols-clipper {
  overflow: visible !important; }

.advancedSaasVisibilityAppListTable .appListScroll .ag-root-body,
.advancedSaasVisibilityAppListTable .appListScroll .ag-body-viewport .table-list,
.advancedSaasVisibilityAppListTable .appListScroll .ag-body-viewport-wrapper,
.advancedSaasVisibilityAppListTable .appListScroll .ag-ltr .ag-body-viewport,
.advancedSaasVisibilityAppListTable .appListScroll .ag-center-cols-container {
  overflow-y: scroll !important; }

.advancedSaasVisibilityAppListTable .controlRow {
  padding: 8px 4px; }
  .advancedSaasVisibilityAppListTable .controlRow .bulkControls {
    display: flex; }
    .advancedSaasVisibilityAppListTable .controlRow .bulkControls .bulkManualRiskList li {
      display: flex;
      cursor: pointer; }
      .advancedSaasVisibilityAppListTable .controlRow .bulkControls .bulkManualRiskList li:hover {
        background-color: #eff7ff; }
      .advancedSaasVisibilityAppListTable .controlRow .bulkControls .bulkManualRiskList li label {
        display: flex;
        justify-content: center; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol div.form-group,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol div.form-group {
    width: 200px; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol div.action-button,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol div.action-button {
    font-size: 12px;
    cursor: pointer;
    color: #006fcc;
    padding: 5px 10px;
    margin-left: 7px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: #fff; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol input.form-control,
  .advancedSaasVisibilityAppListTable .controlRow .searchCol input.form-control:focus,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol input.form-control,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol input.form-control:focus {
    box-shadow: none;
    color: #4a4a4a; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol button.dropdown-bulk,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol button.dropdown-bulk {
    padding-top: 5px;
    padding-bottom: 2px; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu {
    width: 150px; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu div[role="button"],
    .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu div[role="button"] {
      padding: 8px; }
      .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu div[role="button"]:hover,
      .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu div[role="button"]:hover {
        background-color: #eff7ff; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu .sanctionedIcon,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu .sanctionedIcon {
      background-color: #1f75b2;
      color: #fff;
      border-radius: 2px;
      padding: 2px 6px;
      margin: 5px;
      display: inline; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu .toleratedIcon,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu .toleratedIcon {
      background-color: #282f78;
      color: #fff;
      border-radius: 2px;
      padding: 2px 6px;
      margin: 5px;
      display: inline; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu .unsanctionedIcon,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu .unsanctionedIcon {
      background-color: #5db8e4;
      color: #fff;
      border-radius: 2px;
      padding: 2px 5px;
      margin: 5px;
      display: inline; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu .unknownIcon,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu .unknownIcon {
      background-color: #bce4fa;
      padding: 0px 7.2px;
      border-radius: 10px;
      margin: 7px;
      display: inline; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol .dropdown-menu label,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol .dropdown-menu label {
      cursor: pointer;
      color: #333333;
      padding: 0px;
      margin: 6px 0px;
      font-weight: 400;
      font-size: 12px;
      width: 100%; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol .right-inner-addon i,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol .right-inner-addon i {
    padding: 4px 10px;
    color: #686b6f;
    opacity: 1; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol input[type="text"],
  .advancedSaasVisibilityAppListTable .controlRow .tagCol input[type="text"] {
    border: 1px solid #e5e5e5;
    width: 100%; }
  .advancedSaasVisibilityAppListTable .controlRow .searchCol button,
  .advancedSaasVisibilityAppListTable .controlRow .tagCol button {
    padding: 3.5px 10px;
    margin-left: 7px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: #fff; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol button.buttonLoading,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol button.buttonLoading {
      opacity: 0.7;
      pointer-events: none; }
    .advancedSaasVisibilityAppListTable .controlRow .searchCol button img,
    .advancedSaasVisibilityAppListTable .controlRow .tagCol button img {
      height: 10px; }

.advancedSaasVisibilityAppListTable .applicationListTable {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #f6f6f6; }

.advancedSaasVisibilityAppListTable .btn-selected,
.advancedSaasVisibilityAppListTable .btn-selected:hover {
  color: #333333;
  background-color: #d3e8fc !important;
  font-weight: bold; }

.advancedSaasVisibilityAppListTable .time-range-filter-option {
  padding-top: 8px;
  padding-bottom: 8px; }
  .advancedSaasVisibilityAppListTable .time-range-filter-option:hover {
    background-color: #eff7ff; }

.advancedSaasVisibilityAppListTable .errorMessage {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px; }

.__react_component_tooltip {
  opacity: 1 !important;
  max-width: 500px;
  word-break: break-all;
  padding: 8px !important;
  font-size: 12px !important; }

.__react_component_tooltip.type-light.border {
  border: 0 !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important; }

.__react_component_tooltip.type-light.border.place-top:before {
  border-top: 8px solid rgba(0, 0, 0, 0.1) !important; }
