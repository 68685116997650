.login-page {
  .position-static {
    position: static;
  }
  .panel-centered {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  #signin-page {
    #heading-signIn {
      color: #666666;
      background-color: transparent;
      text-align: center;
      border-color: #dddddd;
      border-bottom-color: rgb(221, 221, 221);
      border-bottom: 0px;
      padding-top: 30px;
      padding-bottom: 0px;
    }
    #heading-signIn h1 {
      font-size: 32px;
      font-weight: 300;
    }
  }

  #data-base-login {
    .form-control {
      margin-bottom: 10px;
    }
  }

  #footer {
    clear: both;
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0px;
    .footer-logo {
      position: absolute;
      right: 0;
      width: 120px;
      margin: 0;
      opacity: 0.2;
    }
  }
}
