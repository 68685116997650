.modalContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  left: auto;
  margin: auto;
  z-index: 2;
  color: #333333;
  font-size: 12px;
  overflow-y: none; }
  .modalContainer .modalBackdrop {
    background: #1f2733;
    opacity: 0.8;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0; }
  .modalContainer .modalDialog {
    width: 360px;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    margin: auto;
    top: 25%; }
    .modalContainer .modalDialog > .title {
      color: #333333;
      font-size: 16px;
      background-color: #f9f9f9;
      padding: 8px 16px;
      border-radius: 4px; }
    .modalContainer .modalDialog > .body {
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 12px 22px; }
    .modalContainer .modalDialog > .footer {
      text-align: right; }
      .modalContainer .modalDialog > .footer > div {
        cursor: pointer;
        text-align: center;
        display: inline-block;
        padding: 8px 10px;
        margin: 8px;
        border-radius: 4px;
        min-width: 64px; }
      .modalContainer .modalDialog > .footer > .cancel {
        background-color: #fff;
        color: #1f75b2;
        border: 1px solid #d4d4d4; }
      .modalContainer .modalDialog > .footer > .yes {
        background-color: #1f75b2;
        color: #fff; }
