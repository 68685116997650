.sectionBox {
  background: #ffffff;
  border: 1px solid #dadbdb;
  border-radius: 4px; }

.sectionHeader {
  color: #333;
  border-bottom: 1px solid #dadbdb;
  padding: 12px 16px 12px 16px;
  font-size: 16px; }

.displayApplicationList {
  padding: 0px !important;
  align-items: initial !important; }
  .displayApplicationList .advancedSaasVisibilityAppSelectedList .tableListPagination {
    padding-left: 12px !important; }
