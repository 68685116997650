.reactBoostrapModal :global(.modal-body),
.reactBoostrapModal :global(.modal-title) {
  white-space: normal; }

:global(.reactBoostrapModal.modal.in) {
  display: block !important; }

.reactBoostrapModal :global(.modal-content .modal-header) {
  background-color: #f9f9f9 !important; }
  .reactBoostrapModal :global(.modal-content .modal-header) :global(.modal-title) {
    color: #333333 !important;
    font-weight: 400 !important;
    font-size: 16px; }
  .reactBoostrapModal :global(.modal-content .modal-header) :global(.close) {
    font-size: 20px !important;
    margin-top: 0px !important;
    color: #333333 !important; }

.reactBoostrapModal :global(.modal-content .modal-footer) {
  text-align: right !important; }
  .reactBoostrapModal :global(.modal-content .modal-footer) :global(.btn-cancel) {
    color: #006FCC !important; }
  .reactBoostrapModal :global(.modal-content .modal-footer) :global(.btn-apply) {
    background: #006FCC; }

.modalBackdrop {
  display: none; }
  :global(.modalBackdrop.in) {
    display: block !important; }
