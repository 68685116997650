.advancedSaasVisibilityAppSelectedModal {
  width: 100%; }
  .advancedSaasVisibilityAppSelectedModal .tableListPagination {
    display: flex;
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px; }
    .advancedSaasVisibilityAppSelectedModal .tableListPagination .title {
      flex: 1 auto; }
    .advancedSaasVisibilityAppSelectedModal .tableListPagination .control {
      flex: 1 auto; }
  .advancedSaasVisibilityAppSelectedModal .activityGroupHeader {
    margin-left: 10px; }
  .advancedSaasVisibilityAppSelectedModal .controlRow {
    padding: 8px 4px;
    display: flex; }
    .advancedSaasVisibilityAppSelectedModal .controlRow .searchCol {
      flex: 1 auto; }
      .advancedSaasVisibilityAppSelectedModal .controlRow .searchCol div.form-group {
        width: 324px;
        padding-left: 16px; }
      .advancedSaasVisibilityAppSelectedModal .controlRow .searchCol input.form-control,
      .advancedSaasVisibilityAppSelectedModal .controlRow .searchCol input.form-control:focus {
        box-shadow: none;
        color: #4a4a4a; }
      .advancedSaasVisibilityAppSelectedModal .controlRow .searchCol .right-inner-addon i {
        padding: 4px 10px;
        color: #686b6f;
        opacity: 1; }
      .advancedSaasVisibilityAppSelectedModal .controlRow .searchCol input[type='text'] {
        border: 1px solid #e5e5e5;
        width: 100%; }
  .advancedSaasVisibilityAppSelectedModal #app_selected_list {
    font-family: 'Lato'; }
  .advancedSaasVisibilityAppSelectedModal .ag-theme-balham .ag-header {
    font-family: 'Lato' !important; }
  .advancedSaasVisibilityAppSelectedModal .ag-body-viewport {
    max-height: 260px;
    overflow-y: scroll !important;
    overflow-x: hidden !important; }
