.advancedSaasVisibilityUserList {
  font-style: normal;
  background-color: #ffffff;
  position: relative;
  /* header              */ }
  .advancedSaasVisibilityUserList .loadingIndicator {
    width: 100%;
    display: flex;
    position: relative; }
    .advancedSaasVisibilityUserList .loadingIndicator .alignIndicator {
      position: absolute;
      width: 100%;
      top: -13px; }
  .advancedSaasVisibilityUserList .disabledSection {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    opacity: 0.4;
    pointer-events: auto; }
  .advancedSaasVisibilityUserList .headerText {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    padding-top: 5px;
    display: flex;
    align-items: flex-end; }
    .advancedSaasVisibilityUserList .headerText .headerCountText {
      color: #333333;
      font-style: italic;
      font-size: 12px;
      padding-left: 4px;
      margin-bottom: 4px; }
    .advancedSaasVisibilityUserList .headerText .betaTag {
      padding: 4px 11px;
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      background-color: #28c2a6;
      font-weight: bold;
      border-radius: 16px;
      margin: 8px; }
  .advancedSaasVisibilityUserList .errorState {
    padding: 50px; }
  .advancedSaasVisibilityUserList .headerSubtext {
    color: #707070;
    font-size: 12px;
    padding-bottom: 9px; }
  .advancedSaasVisibilityUserList .headerTitle {
    z-index: 1;
    padding: 0px !important; }
  .advancedSaasVisibilityUserList .userListTable {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #f6f6f6; }
