.highLevelCounts {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 0 !important;
  margin-top: 10px;
  background-color: #ffffff; }
  .highLevelCounts a,
  .highLevelCounts a:hover,
  .highLevelCounts a:active {
    color: #337ab7; }
  .highLevelCounts .appsOverviewHeader {
    border-bottom: 1px solid #EAEAEA;
    padding: 10px; }
    .highLevelCounts .appsOverviewHeader .title {
      font-size: 16px;
      color: #32393F;
      padding: 0px 20px 0px 10px;
      font-weight: 500; }
    .highLevelCounts .appsOverviewHeader .loadingOpacity {
      pointer-events: none;
      opacity: 0.4; }
  .highLevelCounts .appsOverviewBody {
    padding: 30px; }
    .highLevelCounts .appsOverviewBody .borderedCount {
      border-left: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4; }
    .highLevelCounts .appsOverviewBody .count {
      font-size: 48px;
      font-weight: 200; }
    .highLevelCounts .appsOverviewBody .countLabel {
      font-size: 18px;
      font-weight: 300;
      line-height: 20px; }
      .highLevelCounts .appsOverviewBody .countLabel .tooltip {
        border: 0;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important;
        opacity: 1 !important; }
      .highLevelCounts .appsOverviewBody .countLabel .tooltip:before {
        border-top: 8px solid rgba(0, 0, 0, 0.1) !important; }
      .highLevelCounts .appsOverviewBody .countLabel svg {
        position: relative;
        top: 2px; }
    .highLevelCounts .appsOverviewBody .smallerSize {
      font-size: 24px; }
    .highLevelCounts .appsOverviewBody .dataCount {
      padding-top: 15px;
      display: flex;
      align-items: center; }
    .highLevelCounts .appsOverviewBody .bytesCount {
      padding-right: 0; }
      .highLevelCounts .appsOverviewBody .bytesCount .upDownCounts {
        padding-right: 0;
        white-space: nowrap;
        padding-left: 35px; }
    .highLevelCounts .appsOverviewBody .arrowIcon {
      padding-right: 5px; }
    .highLevelCounts .appsOverviewBody .error {
      padding: 50px; }
