.SerialNumberForm .pageHeader {
  border-bottom: 0px;
  font-size: 24px;
  padding: 16px 16px 16px 16px;
  margin: 0px -20px 20px -20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333333; }

.SerialNumberForm .description {
  color: #707070;
  font-size: 12px; }

.SerialNumberForm .table > thead > tr > th {
  background-color: #fafafa;
  font-family: "Lato";
  text-transform: uppercase;
  color: #999;
  font-weight: 300;
  border-bottom: 0px;
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 12px; }

.SerialNumberForm .table-condensed > thead > tr > td,
.SerialNumberForm .table-condensed > tbody > tr > td,
.SerialNumberForm .table-condensed > tfoot > tr > td {
  padding: 8px; }

.SerialNumberForm table.valign-middle > tbody > tr > td,
.SerialNumberForm table.valign-middle > tbody > tr > th {
  vertical-align: middle; }

.SerialNumberForm .table-condensed > thead > tr > th:first-of-type,
.SerialNumberForm .table-condensed > tbody > tr > td:first-of-type {
  padding-left: 15px; }

.SerialNumberForm .table-condensed > thead > tr > th:last-of-type,
.SerialNumberForm .table-condensed > tbody > tr > td:last-of-type {
  padding-right: 15px; }

.SerialNumberForm .table {
  margin-bottom: 0px; }

.SerialNumberForm .dot.dot-success, .SerialNumberForm .dot.dot-active, .SerialNumberForm .dot.monitoring {
  background-color: #00BABC; }

.SerialNumberForm .dot.not_connected {
  background-color: #f2f2f2; }

.SerialNumberForm .dot.verifying {
  background-color: #ef9700; }

.SerialNumberForm .dot.verified {
  background-color: #008CBA; }

.SerialNumberForm .dot.error {
  background-color: #bc043b; }

.SerialNumberForm .icon-space {
  margin-right: 6px; }

.SerialNumberForm .dot {
  display: inline-block;
  border-radius: 50px;
  width: 12px;
  height: 12px;
  background-color: #999; }

.SerialNumberForm .deleteAction {
  border: 0px;
  background: transparent;
  color: #006fcc; }
