.overlayBackgroundWhite {
  pointer-events: none;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 2; }
  .overlayBackgroundWhite .loadingIndicator {
    width: 100%;
    display: flex;
    position: relative; }
    .overlayBackgroundWhite .loadingIndicator .alignIndicator {
      position: absolute;
      width: 100%;
      top: -13px; }

.overlayMessage {
  top: 50%;
  position: absolute;
  left: 50%;
  margin-top: -25px;
  margin-left: -50px;
  width: 100px; }
