.usersAndGroupsModalContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  margin: auto;
  z-index: 1;
  color: #333333;
  font-size: 12px; }
  .usersAndGroupsModalContainer .modalBackdrop {
    background: #1f2733;
    opacity: 0.8;
    position: fixed;
    height: 100%;
    width: 100%; }
  .usersAndGroupsModalContainer .modalDialog {
    width: 880px;
    background-color: #fff;
    position: relative;
    margin: auto;
    border-radius: 4px;
    top: 15%; }
    .usersAndGroupsModalContainer .modalDialog .modalHeader {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: #f9f9f9;
      padding: 8px 16px;
      font-size: 16px;
      border-bottom: 1px solid #d4d4d4; }
    .usersAndGroupsModalContainer .modalDialog .modalBody {
      padding: 18px 20px 8px 20px; }
      .usersAndGroupsModalContainer .modalDialog .modalBody .modalTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline; }
        .usersAndGroupsModalContainer .modalDialog .modalBody .modalTitle .maintext {
          font-size: 20px; }
        .usersAndGroupsModalContainer .modalDialog .modalBody .modalTitle .subtext {
          font-style: italic;
          color: #707070; }
      .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px; }
        .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalSearch {
          width: 300px; }
          .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalSearch .form-group {
            margin-bottom: 0 !important; }
        .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .switch-sm {
          margin-left: 5px !important;
          margin-bottom: 1px !important;
          height: 16px;
          width: 32px; }
          .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .switch-sm .slider:before {
            height: 14px;
            width: 14px;
            bottom: 1px;
            left: 1px;
            top: 1px; }
          .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .switch-sm .on,
          .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .switch-sm .off,
          .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .switch-sm img {
            animation: fadeIn ease 0.8s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
          .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .switch-sm input:checked + .slider:before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px); }
        .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .ruleEnabledIcon {
          width: 12px;
          height: 19px;
          position: relative;
          left: 8px;
          top: -5px; }
        .usersAndGroupsModalContainer .modalDialog .modalBody .modalControls .modalToggle .ruleDisabledIcon {
          width: 12px;
          height: 19px;
          position: relative;
          top: -5px;
          left: -7px; }
    .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable {
      height: 275px;
      font-size: 12px;
      color: #333333; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable input {
        margin: 0px 8px 0px 0px;
        height: 16px;
        width: 16px;
        padding-right: 4px; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .usersAndGroupsHeaderText {
        border-right: 1px solid #bdc3c7; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .spacerHeader {
        padding: 5px;
        border-top: 1px solid #bdc3c7;
        border-bottom: 1px solid #bdc3c7;
        display: inline-block;
        width: 4%; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .iconsHeader {
        padding: 5px;
        border-top: 1px solid #bdc3c7;
        border-bottom: 1px solid #bdc3c7;
        display: inline-block;
        width: 15%;
        font-weight: 600;
        cursor: pointer; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .namesHeader {
        padding: 5px;
        border-top: 1px solid #bdc3c7;
        border-bottom: 1px solid #bdc3c7;
        display: inline-block;
        width: 81%;
        font-weight: 600; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .usersAndGroupsTableRow:nth-child(even) {
        background-color: #f4f5f5; }
      .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .usersAndGroupsTableRow {
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer; }
        .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .usersAndGroupsTableRow:hover {
          background-color: #cfe8fc; }
        .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .usersAndGroupsTableRow .icon {
          padding: 5px;
          display: inline-flex;
          width: 20%; }
        .usersAndGroupsModalContainer .modalDialog .usersAndGroupsTable .usersAndGroupsTableRow .name {
          padding: 5px;
          display: inline-flex;
          width: 85%;
          user-select: none; }
    .usersAndGroupsModalContainer .modalDialog .modalTable .selectedCounts {
      padding: 36px 16px 12px; }
    .usersAndGroupsModalContainer .modalDialog .modalFooter {
      background-color: #f4f5f5;
      border-top: 1px solid #dadbdb; }
      .usersAndGroupsModalContainer .modalDialog .modalFooter .ag-body-viewport {
        overflow-x: scroll !important;
        overflow-y: hidden !important; }
    .usersAndGroupsModalContainer .modalDialog .modalFooter {
      padding: 12px 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
      .usersAndGroupsModalContainer .modalDialog .modalFooter .button {
        height: 32px;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer; }
        .usersAndGroupsModalContainer .modalDialog .modalFooter .button.reset {
          background-color: #fff;
          color: #006fcc;
          border: 1px solid #dadbdb; }
        .usersAndGroupsModalContainer .modalDialog .modalFooter .button.cancel {
          background-color: #fff;
          color: #006fcc;
          border: 1px solid #dadbdb; }
        .usersAndGroupsModalContainer .modalDialog .modalFooter .button.add {
          background-color: #006fcc;
          color: #fff;
          margin-left: 12px; }
