@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  font-family: Lato;
  font-style: normal;
}

#general-container {
  position: relative;
  min-height: 100%;
}

#main-container {
  padding-bottom: 60px;
  min-width: 1180px;
  margin-top: 50px;
}

.form-group > label {
  color: #707070;
}

.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-right: 30px;
}
.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.4;
}

.advancedSaasVisibilityDashboard {
  // font-family: Lato;
  font-style: normal;

  .advancedSaasVisibilityDashboardHeader {
    background-color: #ffffff;

    // dashboard header
    .pageTitle {
      color: #333333;
      font-size: 24px;
      font-weight: 400;
      padding-top: 5px;
      display: flex;

      .betaTag {
        padding: 4px 11px;
        font-size: 12px;
        line-height: 12px;
        color: #fff;
        background-color: #28c2a6;
        font-weight: bold;
        border-radius: 16px;
        margin: 8px;
      }
    }

    .pageTitleSubtext {
      font-size: 12px;
      color: #707070;
      padding-bottom: 9px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .dashboardContent {
    position: relative;

    // basic layout
    .leftPanelPadding {
      padding: 0px 5px 10px 0px;
    }

    .rightPanelPadding {
      padding: 0px 0px 10px 5px;
    }
  }
}

.no-padding {
  padding: 0px 0px 0px 0px;
}

.white-smoke-background {
  background-color: #efefef;
}

.isFlex {
  display: flex;
  min-height: 100%;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  display: flex;
  flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.margin-top-xlg {
  margin-top: 20px;
}

#footer{
  clear: both;
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0px;
  .logo{
    img{
      float: right;
      position: absolute;
      right: 0;
      margin: 0;
      opacity: 0.2;
    }
  }
  .row{
    padding: 18px 20px 30px 20px;
  }
}

.form-group {
  margin-bottom: 28px; /* More space between form fields */
  .col-sm-9{
    label{
      padding-top: 9px;
      font-weight: normal !important;
    }
  }

}

button:hover, button:focus {
  text-decoration: underline;
}

.switch .on {
  display: none;
  float: left;
  margin-left: 10px;
}

.switch .off {
  float: right;
  margin-right: 10px;
}

.switch .on, .switch .off {
  color: #fff;
  font-size: 12px;
  margin-top: 3px;
}

.switch input:checked + .slider .on {
  display: block;
}

.switch input:checked + .slider .off {
  display: none;
}

.panel {
  border: 0px;
  box-shadow: none;
  border: thin solid #DADCDE;
  padding-bottom: 2px;
  border-radius: 1px;
}

.btn.btn-primary {
  background-color: #0ba4e8;
  color: #fff;
  border: 1px solid #0ba4e8;
}

