.topCategoriesByApp {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 10px;
  background-color: #ffffff; }
  .topCategoriesByApp a,
  .topCategoriesByApp a:hover,
  .topCategoriesByApp a:active {
    color: #006fcc; }
  .topCategoriesByApp .tooltip {
    border: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important;
    opacity: 1 !important; }
  .topCategoriesByApp .tooltip:before {
    border-top: 8px solid rgba(0, 0, 0, 0.1) !important; }
  .topCategoriesByApp .dropdown {
    cursor: pointer; }
  .topCategoriesByApp .topCategoriesByAppHeader {
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: flex;
    align-items: center; }
    .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions {
      display: inline-block; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .dropDownToggleOptions {
        color: #1f75b2 !important;
        border: none;
        padding: 0px 8px;
        font-size: 16px; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .dropDownToggleOptions:hover,
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .dropDownToggleOptions:active,
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .dropDownToggleOptions:focus,
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .btn-default:focus,
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .btn-default:active,
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .open > .dropdown-toggle.btn-default {
        background-color: #fff !important;
        color: #1f75b2 !important;
        text-decoration: underline; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions .caret {
        color: #333333; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions li {
        color: #333333;
        padding: 8px;
        cursor: pointer;
        font-size: 14px; }
        .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions li:hover {
          background-color: #eff7ff; }
        .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions li.selectedItem, .topCategoriesByApp .topCategoriesByAppHeader .dropDownOptions li.selectedItem:hover {
          background-color: #d3e8fc; }
  .topCategoriesByApp .topCategoriesBody {
    padding: 16px 0px; }
