.advancedSaasVisibilityAppModalListTable .__react_component_tooltip {
  opacity: 1 !important;
  max-width: 320px;
  word-break: break-all; }
  .advancedSaasVisibilityAppModalListTable .__react_component_tooltip .helperTextTooltip {
    font-size: 12px;
    color: #717171; }
    .advancedSaasVisibilityAppModalListTable .__react_component_tooltip .helperTextTooltip .descriptionText {
      padding: 5px 20px; }
    .advancedSaasVisibilityAppModalListTable .__react_component_tooltip .helperTextTooltip .header {
      color: #000; }
    .advancedSaasVisibilityAppModalListTable .__react_component_tooltip .helperTextTooltip .noRisk {
      color: #28c2a6; }
    .advancedSaasVisibilityAppModalListTable .__react_component_tooltip .helperTextTooltip .maxRisk {
      color: #c83a4b; }

.advancedSaasVisibilityAppModalListTable .activityGroupHeader {
  margin-left: 10px; }

.advancedSaasVisibilityAppModalListTable #app_modal_list {
  font-family: 'Lato'; }

.advancedSaasVisibilityAppModalListTable .ag-theme-balham .ag-header {
  font-family: 'Lato' !important; }

.advancedSaasVisibilityAppModalListTable .ag-header-cell[col-id='risk'] .ag-react-container {
  width: 100%; }

.advancedSaasVisibilityAppModalListTable .controlRow {
  padding: 8px 4px;
  display: flex; }
  .advancedSaasVisibilityAppModalListTable .controlRow .searchCol {
    flex: 1 auto; }
    .advancedSaasVisibilityAppModalListTable .controlRow .searchCol div.form-group {
      width: 324px;
      padding-left: 16px; }
    .advancedSaasVisibilityAppModalListTable .controlRow .searchCol input.form-control,
    .advancedSaasVisibilityAppModalListTable .controlRow .searchCol input.form-control:focus {
      box-shadow: none;
      color: #4a4a4a; }
    .advancedSaasVisibilityAppModalListTable .controlRow .searchCol .right-inner-addon i {
      padding: 4px 10px;
      color: #686b6f;
      opacity: 1; }
    .advancedSaasVisibilityAppModalListTable .controlRow .searchCol input[type='text'] {
      border: 1px solid #e5e5e5;
      width: 100%; }

.advancedSaasVisibilityAppModalListTable .tableListPagination {
  display: flex;
  padding: 5px 10px 5px 10px;
  margin-bottom: 10px; }
  .advancedSaasVisibilityAppModalListTable .tableListPagination .title {
    flex: 1 auto; }
  .advancedSaasVisibilityAppModalListTable .tableListPagination .control {
    flex: 1 auto; }

.advancedSaasVisibilityAppModalListTable .table-list .ag-body-viewport {
  max-height: 365px !important; }
