.topCategoriesByAppFooter {
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0px; }
  .topCategoriesByAppFooter a,
  .topCategoriesByAppFooter a:hover,
  .topCategoriesByAppFooter a:active {
    color: #337ab7; }
  .topCategoriesByAppFooter .riskLegendItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 0;
    user-select: none; }
  .topCategoriesByAppFooter .inactiveItem {
    opacity: 0.3; }
  .topCategoriesByAppFooter .resetFilter {
    font-size: 12px;
    color: #1f75b2 !important; }
  .topCategoriesByAppFooter [role='button']:focus {
    outline: none; }
