.editable-field .visible-edit-mode {
  display: none; }

.editable-field.edit-mode .visible-edit-mode {
  display: initial; }

.editable-field.edit-mode .visible-show-mode {
  display: none; }

.editable-field.edit-mode span.hint {
  display: inherit;
  margin-left: -14px;
  margin-top: 2px;
  padding: 3px 12px;
  height: 28px; }

.editable-field.edit-mode span.error-message {
  display: inherit;
  margin-left: -14px;
  margin-bottom: 2px;
  margin-top: 2px;
  padding: 3px 12px;
  height: 28px; }

.editable-field .btn .caret {
  margin-left: 5px; }

.editable-field .btn.btn-default.btn-xs.btn-editable-actions {
  border-color: transparent; }

.editable-field .btn {
  padding: 5.25px 10.5px !important; }

.editable-field .btn .glyphicon {
  color: #999;
  font-size: 14px; }

.editable-field .editable-field-edit-btn {
  margin-left: 5px; }

.editable-field button:focus {
  outline: none; }

.editable-field .btn:hover, .editable-field .btn:active {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  color: #333333; }

.editable-field .input-field, .editable-field .editable-field-save-btn, .editable-field .editable-field-cancel-btn {
  width: 75%;
  margin-right: 5px; }

.editable-field .glyphicon.glyphicon-ok {
  color: green; }

.editable-field .glyphicon.glyphicon-remove {
  color: red; }

.editable-field .placeholder {
  color: grey; }

.editable-field input.input-field {
  margin-left: -14px;
  padding: 3px 12px;
  height: 28px;
  display: inline-block; }

.editable-field .div-ok-button {
  border-right: 1px solid #ccc;
  padding-right: 5px;
  margin-right: 5px;
  display: inline-block; }

.editable-field .div-remove-button {
  display: inline-block; }

table.editable-fields td {
  padding: 5px 18px;
  line-height: 30px; }
