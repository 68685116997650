.advancedSaasVisibilityAppSelectedList {
  width: 100%; }
  .advancedSaasVisibilityAppSelectedList .tableListPagination {
    display: flex;
    padding: 5px 10px 5px 10px; }
    .advancedSaasVisibilityAppSelectedList .tableListPagination .title {
      flex: 1 auto; }
    .advancedSaasVisibilityAppSelectedList .tableListPagination .control {
      flex: 1 auto; }
  .advancedSaasVisibilityAppSelectedList .controlRow {
    padding: 8px 8px; }
    .advancedSaasVisibilityAppSelectedList .controlRow .searchCol div.form-group {
      width: 324px; }
    .advancedSaasVisibilityAppSelectedList .controlRow .searchCol input.form-control,
    .advancedSaasVisibilityAppSelectedList .controlRow .searchCol input.form-control:focus {
      box-shadow: none;
      color: #4a4a4a; }
    .advancedSaasVisibilityAppSelectedList .controlRow .searchCol .right-inner-addon i {
      padding: 4px 10px;
      color: #686b6f;
      opacity: 1; }
    .advancedSaasVisibilityAppSelectedList .controlRow .searchCol input[type='text'] {
      border: 1px solid #e5e5e5;
      width: 100%; }
  .advancedSaasVisibilityAppSelectedList #app_selected_list {
    font-family: 'Lato'; }
  .advancedSaasVisibilityAppSelectedList .ag-theme-balham .ag-header {
    font-family: 'Lato' !important; }
  .advancedSaasVisibilityAppSelectedList .ag-body-viewport {
    max-height: 260px;
    overflow-y: scroll !important;
    overflow-x: hidden !important; }
