.setupText {
  background-color: #f0f7ff;
  border-bottom: 2px solid #227dbf;
  height: 47px; }
  .setupText :global(.btn) {
    float: right;
    font-size: 10px;
    margin-top: 10px;
    margin-right: 10px; }
  .setupText span {
    font-size: 10px;
    top: 10px;
    left: 23px;
    position: relative; }
    .setupText span .binocularsIcon {
      margin-right: 15px; }

.mainText {
  top: 400px;
  left: 450px;
  font-size: 60px;
  position: absolute; }

.aditionalText {
  left: 530px;
  top: 480px;
  position: absolute; }
