.actionSection {
  height: 344px; }
  .actionSection .content {
    padding-bottom: 23px !important; }
    .actionSection .content .selectField {
      margin-bottom: 30px; }
      .actionSection .content .selectField option {
        padding: 8px 0px 8px 16px; }
    .actionSection .content .descriptionText {
      font-style: italic;
      font-weight: 400;
      margin-bottom: 20px; }
    .actionSection .content .warning-message {
      margin-bottom: 30px;
      padding: 10px;
      background: #fff2f2;
      border-radius: 4px;
      display: flex; }
      .actionSection .content .warning-message .warning-message-content {
        flex: 1 auto; }
        .actionSection .content .warning-message .warning-message-content .warningBlockedAction {
          margin-right: 10px; }
    .actionSection .content .select-form-field {
      position: relative; }
      .actionSection .content .select-form-field svg.warningBlockedAction {
        position: absolute;
        top: 36px;
        right: 22px; }
    .actionSection .content .userActivity {
      margin-bottom: 26px !important; }
