.deleteSerialNumberModal.modal.in {
  display: block; }

.deleteSerialNumberModal .modal-content .modal-header {
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 6px 6px 0px 0px;
  padding: 8px 16px; }
  .deleteSerialNumberModal .modal-content .modal-header .modal-title {
    color: #333333 !important;
    font-weight: 400 !important;
    font-size: 16px; }
  .deleteSerialNumberModal .modal-content .modal-header .close {
    font-size: 20px;
    text-shadow: none;
    opacity: 1; }

.deleteSerialNumberModal .modal-content .modal-footer {
  text-align: left;
  background-color: #E5E5E5;
  border-radius: 0px 0px 6px 6px; }
  .deleteSerialNumberModal .modal-content .modal-footer .btn {
    padding: 5.25px 10.5px;
    border: 1px solid #cccccc; }
  .deleteSerialNumberModal .modal-content .modal-footer .btn-temp {
    background-color: #fff; }

.deleteSerialNumberModal .modal-content .modal-footer,
.deleteSerialNumberModal .modal-content .modal-body {
  padding-left: 24px;
  padding-right: 24px; }
