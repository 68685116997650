.advancedSaasVisibilityAppList {
  background-color: #ffffff;
  position: relative;
  /* header              */ }
  .advancedSaasVisibilityAppList [role="button"]:focus {
    outline: none; }
  .advancedSaasVisibilityAppList a,
  .advancedSaasVisibilityAppList a:hover,
  .advancedSaasVisibilityAppList a:active {
    color: #006fcc; }
  .advancedSaasVisibilityAppList .loadingIndicator {
    width: 100%;
    display: flex;
    position: relative; }
    .advancedSaasVisibilityAppList .loadingIndicator .alignIndicator {
      position: absolute;
      width: 100%;
      top: -13px; }
  .advancedSaasVisibilityAppList .loadingSingle {
    width: 100%;
    position: absolute;
    height: 32px;
    background-color: #fff;
    z-index: 1; }
    .advancedSaasVisibilityAppList .loadingSingle .alignIndicator {
      position: absolute;
      width: 100%;
      top: -8px;
      z-index: 1; }
  .advancedSaasVisibilityAppList #appTableTooltip .customTaggingTooltip {
    max-height: 200px;
    min-width: 200px;
    max-width: 280px;
    overflow-y: scroll; }
    .advancedSaasVisibilityAppList #appTableTooltip .customTaggingTooltip .item {
      padding: 8px 16px; }
  .advancedSaasVisibilityAppList .disabledSection {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    opacity: 0.4;
    pointer-events: auto; }
  .advancedSaasVisibilityAppList .headerText {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    padding-top: 5px;
    display: flex;
    align-items: flex-end; }
    .advancedSaasVisibilityAppList .headerText .headerCountText {
      color: #333333;
      font-style: italic;
      font-size: 12px;
      padding-left: 4px;
      margin-bottom: 4px; }
    .advancedSaasVisibilityAppList .headerText .betaTag {
      padding: 4px 11px;
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      background-color: #28c2a6;
      font-weight: bold;
      border-radius: 16px;
      margin: 8px; }
  .advancedSaasVisibilityAppList .right-inner-addon .glyphicon-search {
    font-size: 12px;
    padding: 8px 12px; }
  .advancedSaasVisibilityAppList .errorState {
    padding: 50px; }
  .advancedSaasVisibilityAppList .headerSubtext {
    color: #707070;
    font-size: 12px;
    padding-bottom: 9px; }
  .advancedSaasVisibilityAppList .headerTitle {
    z-index: 1;
    padding: 0px !important; }
  .advancedSaasVisibilityAppList .applicationListTable {
    padding: 0px; }
  .advancedSaasVisibilityAppList #filterMenu .dropdown-menu {
    min-width: 200px; }
  .advancedSaasVisibilityAppList .sanctionedIcon {
    background-color: #1f75b2;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6.9px;
    display: inline; }
    .advancedSaasVisibilityAppList .sanctionedIcon.smallSanctionedIcon {
      padding: 0px 6px;
      margin: 0px 6px 0px 0px; }
  .advancedSaasVisibilityAppList .toleratedIcon {
    background-color: #282f78;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    display: inline; }
    .advancedSaasVisibilityAppList .toleratedIcon.smallSanctionedIcon {
      padding: 0px 6px;
      margin: 0px 6px 0px 0px; }
  .advancedSaasVisibilityAppList .unsanctionedIcon {
    background-color: #5db8e4;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    display: inline; }
    .advancedSaasVisibilityAppList .unsanctionedIcon.smallSanctionedIcon {
      padding: 0px 5px;
      margin: 0px 6px 0px 0px; }
  .advancedSaasVisibilityAppList .unknownIcon {
    background-color: #bce4fa;
    padding: 0px 7.2px;
    border-radius: 10px;
    display: inline;
    color: #bce4fa; }
    .advancedSaasVisibilityAppList .unknownIcon.smallSanctionedIcon {
      padding: 0px 5px;
      margin: 0px 6px 0px 0px; }
  .advancedSaasVisibilityAppList .__react_component_tooltip {
    opacity: 1 !important;
    max-width: 500px;
    word-break: break-all; }
    .advancedSaasVisibilityAppList .__react_component_tooltip .helperTextTooltip {
      font-size: 12px;
      color: #717171; }
      .advancedSaasVisibilityAppList .__react_component_tooltip .helperTextTooltip .descriptionText {
        padding: 5px 20px; }
      .advancedSaasVisibilityAppList .__react_component_tooltip .helperTextTooltip .header {
        color: #000; }
      .advancedSaasVisibilityAppList .__react_component_tooltip .helperTextTooltip .noRisk {
        color: #28c2a6; }
      .advancedSaasVisibilityAppList .__react_component_tooltip .helperTextTooltip .maxRisk {
        color: #c83a4b; }
