.ASVManualRiskScoreDropdown {
  font-size: 12px;
  padding: 0 !important;
  min-width: 150px !important;
  overflow-y: scroll; }
  .ASVManualRiskScoreDropdown .manualRiskList > li {
    cursor: pointer;
    display: flex;
    justify-content: center;
    max-height: 30px;
    line-height: 18px; }
    .ASVManualRiskScoreDropdown .manualRiskList > li:hover {
      background-color: #eff7ff; }
    .ASVManualRiskScoreDropdown .manualRiskList > li label,
    .ASVManualRiskScoreDropdown .manualRiskList > li div {
      display: flex;
      justify-content: center;
      margin: 5px;
      font-weight: 400;
      font-size: 12px; }
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore {
        cursor: pointer;
        margin-left: 6px; }
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore1,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore2,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore3,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore1,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore2,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore3 {
        color: #fff;
        background-color: #28c2a6;
        border-radius: 25px;
        padding: 1px 6.6px; }
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore4,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore5,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore5,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore6,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore7,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore4,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore5,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore5,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore6,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore7 {
        color: #000;
        background-color: #fbd241;
        border-radius: 25px;
        padding: 1px 6.6px; }
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore8,
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore9,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore8,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore9 {
        color: #fff;
        background-color: #c83a4b;
        border-radius: 25px;
        padding: 1px 6.6px; }
      .ASVManualRiskScoreDropdown .manualRiskList > li label .riskScore10,
      .ASVManualRiskScoreDropdown .manualRiskList > li div .riskScore10 {
        color: #fff;
        background-color: #c83a4b;
        border-radius: 25px;
        padding: 1px 3px; }
  .ASVManualRiskScoreDropdown .restoreScore {
    cursor: pointer;
    font-size: 12px;
    border-top: 1px solid #eaeaea;
    text-align: center;
    padding: 8px 16px; }
    .ASVManualRiskScoreDropdown .restoreScore:hover {
      background-color: #eff7ff; }
