.content {
  padding: 16px 28px 0; }
  .content .form-group > label, .content input, .content textarea {
    font-size: 12px !important;
    font-weight: normal; }
  .content .descriptionField .control-label {
    display: block; }
  .content .extraDescription {
    color: #B8B8B8;
    display: inline-block;
    font-style: italic; }
  .content .editionField {
    width: 100%;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 8px 6px 8px; }
  .content .helpText {
    font-size: 10px; }
  .content .radioGroup {
    margin-top: 5px; }
    .content .radioGroup .radioOption {
      margin: 0px 30px 0px 15px; }
      .content .radioGroup .radioOption input {
        margin-right: 10px; }
    .content .radioGroup label span {
      font-weight: normal; }
  .content .validUniqueName {
    border-color: #1DB846; }
  .content .nameField {
    position: relative; }
    .content .nameField svg.checkIcon, .content .nameField svg.exclamationIcon {
      position: absolute;
      top: 36px;
      right: 22px; }
