.asvCheckbox {
  text-align: center;
  display: inline-block;
  border-radius: 2px;
  margin-right: 16px;
  margin-left: 1px;
  height: 16px;
  width: 16px; }
  .asvCheckbox.selected {
    background-color: #38a5ff; }
  .asvCheckbox.notSelected {
    background-color: #fff;
    border: 1px solid #dadbdb; }
