.license .headingTitle {
  font-size: 24px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 6px; }

.license .panel-body {
  padding: 0px; }
  .license .panel-body .table {
    margin-bottom: 0px; }
  .license .panel-body .table > thead > tr > th {
    background-color: #fafafa;
    font-family: "Lato";
    text-transform: uppercase;
    color: #999;
    font-weight: 300;
    border-bottom: 0px;
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px; }

.license .noInLineLicense {
  padding: 20px; }
