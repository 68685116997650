.applicationModalSubTitle {
  padding: 10px 10px 10px 16px; }
  .applicationModalSubTitle .subtitle {
    font-size: 20px;
    font-weight: 400; }
  .applicationModalSubTitle .detailSubtitle {
    display: inline-block;
    margin-left: 40px;
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
    align-items: center; }
