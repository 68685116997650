.onlySelectedApps {
  flex: 1 auto;
  text-align: right;
  margin-right: 23px; }
  .onlySelectedApps .toggleOnlySelectedApps {
    top: 4px !important;
    margin-bottom: 0px !important;
    margin-left: 5px; }
    .onlySelectedApps .toggleOnlySelectedApps .appSelectedDisabled {
      width: 12px;
      position: relative;
      top: -2px;
      left: -7px; }
    .onlySelectedApps .toggleOnlySelectedApps .appSelectedEnabled {
      width: 12px;
      position: relative;
      top: -3px;
      left: 8px; }
