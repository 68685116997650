.AdvancedSaasVisibilityApplicationDetail {
  font-style: normal; }
  .AdvancedSaasVisibilityApplicationDetail [role="button"]:focus {
    outline: none; }
  .AdvancedSaasVisibilityApplicationDetail a,
  .AdvancedSaasVisibilityApplicationDetail a:hover,
  .AdvancedSaasVisibilityApplicationDetail a:active {
    color: #006fcc; }
  .AdvancedSaasVisibilityApplicationDetail .errorMessage {
    padding-top: 12px; }
  .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip {
    opacity: 1 !important;
    max-width: 500px;
    word-break: break-all; }
    .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip .helperTextTooltip {
      font-size: 12px;
      color: #717171; }
      .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip .helperTextTooltip .descriptionText {
        padding: 5px 20px; }
      .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip .helperTextTooltip .header {
        color: #000; }
      .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip .helperTextTooltip .noRisk {
        color: #28c2a6; }
      .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip .helperTextTooltip .maxRisk {
        color: #c83a4b; }
  .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip.type-light.border {
    border: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important; }
  .AdvancedSaasVisibilityApplicationDetail .__react_component_tooltip.type-light.border.place-top:before {
    border-top: 8px solid rgba(0, 0, 0, 0.1); }
  .AdvancedSaasVisibilityApplicationDetail .helperLink {
    font-size: 12px;
    cursor: pointer;
    color: #006fcc;
    padding-left: 8px; }
  .AdvancedSaasVisibilityApplicationDetail .applicationHeader {
    padding: 12px 0px;
    background-color: #ffffff; }
    .AdvancedSaasVisibilityApplicationDetail .applicationHeader .applicationName {
      font-size: 16px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationHeader .breadcrumbNav {
      font-size: 12px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationHeader .breadcrumbNav .arrow {
        color: #333333;
        padding: 0px 8px 0px 16px;
        font-size: 9px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationHeader .breadcrumbNav .current {
        color: #333333; }
      .AdvancedSaasVisibilityApplicationDetail .applicationHeader .breadcrumbNav .betaTag {
        display: inline-block;
        padding: 2px 7px;
        font-size: 11px;
        line-height: 12px;
        color: #fff;
        background-color: #28c2a6;
        font-weight: bold;
        border-radius: 16px;
        margin-left: 8px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationHeader .applicationDetail {
      font-size: 24px;
      display: flex; }
      .AdvancedSaasVisibilityApplicationDetail .applicationHeader .applicationDetail .header {
        font-weight: 300; }
    .AdvancedSaasVisibilityApplicationDetail .applicationHeader .headerSubtext {
      font-size: 12px; }
  .AdvancedSaasVisibilityApplicationDetail .appDetailsLoading {
    display: flex; }
  .AdvancedSaasVisibilityApplicationDetail .applicationTitle {
    background-color: #ffffff; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .appName {
      font-size: 24px;
      font-weight: 300;
      line-height: 1;
      padding-bottom: 5px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails {
      display: flex;
      align-items: center;
      font-size: 12px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails .divider {
        display: inline-flex;
        width: 15px;
        height: 11px;
        border-left: 1px solid #a3a3a3; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails .risk {
        margin: 0px 8px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails .sanctionedStatus {
        color: #333333;
        margin-right: 16px; }
        .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails .sanctionedStatus span {
          display: flex; }
        .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails .sanctionedStatus svg {
          margin: 0px 4px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .subDetails .category {
        color: #333333;
        padding-right: 16px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags {
      padding-right: 16px;
      display: flex;
      align-items: center; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags count {
        color: #006fcc;
        cursor: pointer; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .risks {
      padding-right: 16px;
      display: flex;
      align-items: center; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .risks .dropdown {
        display: inline-block;
        cursor: pointer; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .dropbtn,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .dropbtn {
      color: #006fcc; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .dropbtn:hover,
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .dropbtn:hover {
        color: #006fcc; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .hoverableDropdown,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .hoverableDropdown {
      position: relative;
      display: inline-block;
      background-color: inherit; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .dropdown-content,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .dropdown-content {
      display: none;
      position: absolute;
      height: 10px;
      min-width: 208px;
      z-index: 1;
      width: 100%;
      left: -66px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .dropdown-content .rule,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .dropdown-content .rule {
      cursor: pointer;
      color: #006fcc;
      border-bottom: 1px solid #eaeaea;
      padding: 8px 16px;
      overflow: hidden;
      text-overflow: ellipsis; }
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .dropdown-content .rule:last-of-type,
      .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .dropdown-content .rule:last-of-type {
        border-bottom: none; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .tag,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .tag {
      padding: 8px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules .hoverableDropdown:hover .dropdown-content,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags .hoverableDropdown:hover .dropdown-content {
      display: block; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules div.tooltipMenu,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags div.tooltipMenu {
      position: relative;
      display: inline; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules div.tooltipMenu span,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags div.tooltipMenu span {
      top: 10px;
      margin-left: -19%;
      position: absolute;
      min-width: 225px;
      background: #fff;
      visibility: visible;
      border-radius: 6px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border: 1px solid #eaebeb; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .securityRules div.tooltipMenu span:after,
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .customTags div.tooltipMenu span:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-bottom: 8px solid white;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
    .AdvancedSaasVisibilityApplicationDetail .applicationTitle .count {
      color: #006fcc; }
  .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow {
    margin-top: 0;
    display: flex;
    align-items: center;
    padding: 12px 0px;
    background-color: #eaeaea;
    height: 50px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterIconDisabled {
      padding: 8px 10px;
      height: 32px;
      width: 32px;
      background-color: #a3a3a3;
      border-radius: 20px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup {
      font-size: 12px;
      display: inline-block;
      margin-left: 12px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .filterTitle {
        padding: 6px 16px 6px 32px;
        display: inline-block;
        border-top: 1px solid #d4d4d4;
        border-left: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
        border-right: 1px solid #d4d4d4;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        background-color: #fafafa;
        color: #333333; }
      .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown {
        display: inline-block; }
        .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown .filterDropdown {
          cursor: pointer;
          padding: 6px 32px 6px 16px;
          display: inline-block;
          border-top: 1px solid #d4d4d4;
          border-right: 1px solid #d4d4d4;
          border-bottom: 1px solid #d4d4d4;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          background-color: #fff; }
        .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown .openMenu {
          position: absolute;
          right: 14px;
          top: 8px; }
        .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown .subfilterTitle {
          display: inline-flex;
          color: #1f75b2; }
        .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown li {
          cursor: pointer;
          color: #333333;
          padding: 0px;
          margin: 3px 0px;
          font-weight: 400;
          font-size: 12px;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
          .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown li.selectedItem {
            background-color: #d3e8fc; }
          .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown li:hover {
            background-color: #eff7ff; }
          .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown li label {
            cursor: pointer;
            padding: 8px 0px 8px 16px;
            font-weight: 400; }
      .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .filterRow .filterGroup .dropdown-menu {
        min-width: 225px;
        max-height: 330px; }
  .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .usageRow {
    padding: 8px 0px 12px 0px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .usageRow .usageLoading {
      height: 234px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationUsageFilter .usageRow .usageLoadingIndicator {
      position: relative;
      top: -19px; }
  .AdvancedSaasVisibilityApplicationDetail .applicationDetails {
    padding: 0; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .border {
      border: 1px solid #eaeaea;
      border-radius: 4px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .header {
      font-size: 16px;
      padding: 8px 16px;
      border-bottom: 1px solid #eaeaea; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .header div.action-button {
        font-size: 12px;
        cursor: pointer;
        color: #006fcc;
        padding: 5px 10px;
        margin-left: 7px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        background-color: #fff; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .header .title {
        display: inline-flex; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .descriptionText {
      font-size: 12px;
      color: #333333;
      padding: 16px 24px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .body {
      padding: 0px 0px 12px 0px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo {
      margin: 4px 0px;
      padding: 0px;
      background-color: #ffffff; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo .collapseArrow {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        vertical-align: middle; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo .infoDiv {
        font-size: 12px;
        padding: 8px 32px; }
        .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo .infoDiv .infoLabel {
          color: #717171; }
        .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo .infoDiv .infoText {
          color: #333333;
          display: flex;
          align-items: center;
          vertical-align: middle; }
          .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo .infoDiv .infoText span,
          .AdvancedSaasVisibilityApplicationDetail .applicationDetails .basicInfo .infoDiv .infoText a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search {
      margin: 8px 0px 16px 0px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search input.form-control:focus,
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search textarea.form-control:focus {
        box-shadow: none; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search .right-inner-addon > .form-control {
        border: 1px solid #eaeaea;
        font-size: 12px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search .categoryList {
        padding: 0;
        border-left: 1px solid rgba(196, 196, 196, 0.25); }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search .activeCategory {
        background-color: #fff;
        border-left: 4px solid #56affc;
        padding: 8px 0px 8px 8px;
        color: #333333; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search .inactiveCategory {
        cursor: pointer;
        background-color: #fafafa;
        padding: 8px 0px 8px 8px;
        color: #717171; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .search .greenIcon {
        background-color: #28c2a6;
        color: #fff;
        margin: 5px;
        padding: 2px 6px;
        border-radius: 10px; }
    .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics {
      margin: 4px 0px;
      padding: 0px;
      background-color: #ffffff; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .infoDiv:nth-child(even) .info {
        background-color: #fafafa; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .header {
        display: flex;
        align-items: center;
        padding: 5px 16px; }
        .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .header .controls {
          margin-left: auto; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .descriptionText {
        font-size: 12px;
        color: #333333;
        padding: 16px 24px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .characteristicSummary {
        padding: 0px 24px 12px 34px; }
        .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .characteristicSummary .summaryItem {
          display: flex;
          align-items: center;
          vertical-align: middle;
          font-size: 10px;
          color: #a3a3a3;
          padding: 0; }
          .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .characteristicSummary .summaryItem svg {
            margin-right: 6px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .complianceAttributes {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 225px;
        padding: 0px 0px 24px 0px; }
      .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .infoDiv {
        width: 15%; }
        .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .infoDiv .info {
          font-size: 12px;
          display: flex;
          align-items: center;
          vertical-align: middle;
          padding: 7px 8px;
          justify-content: space-between; }
          .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .infoDiv .info .infoLabel {
            padding: 0px 3px 0px 3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          .AdvancedSaasVisibilityApplicationDetail .applicationDetails .characteristics .infoDiv .info .infoText {
            color: #333333;
            display: flex;
            padding: 0;
            max-width: 15px; }
