.tableListPagination {
  background-color: #f6f6f6;
  font-size: 12px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15); }
  .tableListPagination button {
    padding: 8px 12px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 0px 5px; }
  .tableListPagination .title {
    padding: 12px 0px; }
  .tableListPagination .controls {
    display: flex;
    padding: 8px;
    text-align: right;
    justify-content: flex-end; }
    .tableListPagination .controls .prismaSelect {
      display: inline-block;
      font-size: 12px;
      color: #1f75b2;
      width: 70px;
      height: 24px;
      margin: 0px 5px;
      background: linear-gradient(180deg, #ffffff 0.89%, #fafafa 100.89%);
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      cursor: pointer; }
      .tableListPagination .controls .prismaSelect .arrow {
        padding-top: 2px; }
      .tableListPagination .controls .prismaSelect .dropdown-toggle {
        text-align: left;
        padding: 3px 6px 0px; }
      .tableListPagination .controls .prismaSelect .prismaSelectMenu {
        text-align: center;
        min-width: 69px !important;
        left: -10px; }
        .tableListPagination .controls .prismaSelect .prismaSelectMenu .prismaSelectItem {
          cursor: pointer;
          color: #333333;
          padding: 8px;
          font-size: 12px; }
          .tableListPagination .controls .prismaSelect .prismaSelectMenu .prismaSelectItem:hover {
            background-color: #eff7ff; }
          .tableListPagination .controls .prismaSelect .prismaSelectMenu .prismaSelectItem.selectedItem {
            background-color: #d3e8fc !important; }
    .tableListPagination .controls .rowSelector,
    .tableListPagination .controls .pageSelector {
      display: inline-block;
      padding-left: 8px; }
    .tableListPagination .controls .rowNumberField,
    .tableListPagination .controls .pageNumberField {
      text-align: center;
      color: #1f75b2;
      width: 70px;
      height: 30px;
      margin: 0px 5px;
      background: linear-gradient(180deg, #ffffff 0.89%, #fafafa 100.89%);
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 24px; }
    .tableListPagination .controls button {
      padding: 4px 4px 2px;
      height: 24px;
      color: #333333;
      vertical-align: bottom; }
      .tableListPagination .controls button.buttonLoading {
        opacity: 0.5;
        color: #333; }
