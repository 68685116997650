.advancedSaasVisibilityDiscoveredUserTable .controlRow {
  padding: 8px 4px; }
  .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol :global(div.form-group) {
    width: 200px; }
  .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol :global(input.form-control),
  .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol :global(input.form-control:focus) {
    box-shadow: none;
    color: #4a4a4a; }
  .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol :global(.right-inner-addon) {
    position: relative; }
    .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol :global(.right-inner-addon) i {
      padding: 4px 10px;
      color: #686b6f;
      opacity: 1;
      position: absolute;
      right: 0px; }
  .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol input[type='text'] {
    border: 1px solid #e5e5e5;
    width: 100%; }
  .advancedSaasVisibilityDiscoveredUserTable .controlRow .searchCol :global(div.btn-group) svg {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle; }
