.downloadCsv {
  color: #1f75b2; }
  .downloadCsv span {
    padding: 0px 8px; }

.helperLink button {
  padding: 3.5px 10px;
  margin-left: 7px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #fff; }
  .helperLink button.buttonLoading {
    opacity: 0.7;
    pointer-events: none; }
