.rulesModalContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  left: auto;
  margin: auto;
  z-index: 2;
  color: #333333;
  font-size: 12px;
  overflow-y: none; }
  .rulesModalContainer .modalBackdrop {
    background: #1f2733;
    opacity: 0.8;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0; }
  .rulesModalContainer .modalDialog {
    width: 450px;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    margin: auto;
    top: 25%;
    overflow-wrap: break-word; }
    .rulesModalContainer .modalDialog > .title {
      color: #333333;
      font-size: 16px;
      background-color: #f9f9f9;
      padding: 8px 16px;
      border-radius: 4px; }
    .rulesModalContainer .modalDialog > .body {
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 12px 22px;
      line-height: 20px; }
      .rulesModalContainer .modalDialog > .body a {
        color: #006fcc; }
    .rulesModalContainer .modalDialog > .footer .disabled {
      opacity: 0.5;
      pointer-events: none; }
    .rulesModalContainer .modalDialog > .footer > div {
      display: inline-block; }
      .rulesModalContainer .modalDialog > .footer > div > div.button {
        cursor: pointer;
        text-align: center;
        display: inline-block;
        padding: 8px 16px;
        margin: 8px;
        border-radius: 4px;
        min-width: 64px; }
        .rulesModalContainer .modalDialog > .footer > div > div.button.cancel {
          background-color: #fff;
          color: #006fcc;
          border: 1px solid #d4d4d4; }
        .rulesModalContainer .modalDialog > .footer > div > div.button.yes {
          background-color: #1f75b2;
          color: #fff; }
