.content {
  position: relative; }

.blankState .emptyBox {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.addAppsLink {
  margin-top: 20px; }
  .addAppsLink .finalText {
    font-size: 24px;
    font-weight: 300;
    margin-left: 5px; }
  .addAppsLink .applications-modal-button {
    border: 0;
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    background: transparent;
    color: #006FCC; }

.applicationMessage {
  position: absolute;
  top: 75%;
  padding: 30px; }
  .applicationMessage .warningMessage {
    color: #D84748;
    font-size: 16px; }

.applicationsSection {
  height: 470px; }
  .applicationsSection .sectionHeader {
    padding: 9px 16px 9px 16px; }
