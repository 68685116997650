.snackbarContainer {
  position: fixed;
  height: auto;
  right: 0;
  z-index: 9999; }
  .snackbarContainer .error {
    display: flex;
    background-color: #333333;
    border-radius: 4px;
    font-size: 12px;
    margin: 16px 32px; }
    .snackbarContainer .error .message {
      display: inline-flex;
      color: #ffffff;
      padding: 24px 16px;
      max-width: 275px;
      min-width: 250px;
      line-height: 20px;
      width: 75%;
      align-items: center; }
    .snackbarContainer .error .okay {
      padding: 24px 18px;
      display: inline-flex;
      color: #85d2f4;
      align-items: center;
      user-select: none; }
      .snackbarContainer .error .okay span {
        cursor: pointer;
        padding: 8px; }
