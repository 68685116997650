.left_nav_section {
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  padding-bottom: 34px; }
  .left_nav_section h2 {
    color: #333333;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0px 7px 30px; }
  .left_nav_section ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .left_nav_section ul .left_nav_links {
      list-style-type: none;
      padding: 7px 0px 7px 0px; }
      .left_nav_section ul .left_nav_links:hover {
        background-color: #c2c2c2; }
      .left_nav_section ul .left_nav_links.active {
        background-color: #d8d8d8; }
      .left_nav_section ul .left_nav_links a {
        color: #333333;
        padding-left: 30px;
        text-decoration: none;
        display: block; }
