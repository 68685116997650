.usersAndGroups {
  min-width: 550px;
  margin-right: 22px;
  flex: 1 auto; }
  .usersAndGroups .sectionBox .content {
    padding: 8px 20px 0;
    display: block !important; }
  .usersAndGroups .sectionHeader {
    padding: 8.75px 8px 8.75px 16px !important; }
  .usersAndGroups .right-inner-addon i {
    cursor: default; }
  .usersAndGroups #searchButtonUsersAndGroups {
    top: -4px; }
  .usersAndGroups .searchSection .form-group {
    padding: 0px 8px;
    margin-bottom: 8px; }
  .usersAndGroups .sortArrow {
    margin-right: 12px; }
