:global(.asv-nav) {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 12px 0px; }
  :global(.asv-nav) .link {
    color: #333333;
    text-decoration: none; }
    :global(.asv-nav) .link.active {
      pointer-events: none; }
    :global(.asv-nav) .link:hover {
      color: inherit; }
    :global(.asv-nav) .link div {
      cursor: pointer;
      padding-right: 25px;
      padding-left: 25px;
      padding-bottom: 5px;
      border-bottom: 4px solid #eaeaea;
      -webkit-transition: border 500ms ease-out;
      -moz-transition: border 500ms ease-out;
      -o-transition: border 500ms ease-out;
      transition: border 500ms ease-out;
      pointer-events: none; }
      :global(.asv-nav) .link div.active {
        border-bottom: 4px solid #42a5ed;
        pointer-events: none; }

.disabledSection {
  opacity: 0.4;
  pointer-events: none; }
