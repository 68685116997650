.select-form-field {
  position: relative; }

.select-arrow {
  top: 15px;
  right: 10px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
  width: 17px; }

/* IE11 hide native button */
select::-ms-expand {
  display: none; }

.select-form-field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 32px;
  display: block;
  width: 100%;
  padding: 0px 24px 0px 14px;
  -ms-word-break: normal;
  word-break: normal;
  font-size: 12px; }
