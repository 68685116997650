.usersAndGroupsTable .ag-body-viewport {
  height: 171px; }

.usersAndGroupsTable .ag-root {
  border-color: #d4d4d4 !important;
  border-style: solid !important;
  border-width: 1px 0px !important; }

.usersAndGroupsTable .ag-header {
  border-bottom: 1px solid #d4d4d4 !important;
  background-color: #fff !important;
  color: #333333 !important; }

.usersAndGroupsTable .ag-header-cell {
  background-color: #fff !important; }

.usersAndGroupsTable .ag-header-cell-text {
  flex: 1;
  color: #333333; }

.usersAndGroupsTable .ag-cell {
  cursor: default !important;
  color: #333333 !important; }

.usersAndGroupsTable .ag-row {
  border: 0 !important; }

.usersAndGroupsTable .ag-row-odd {
  background-color: #f4f5f5 !important; }

.usersAndGroupsTable .ag-row-even {
  background-color: #fff !important; }

.usersAndGroupsTable .ag-row-hover,
.usersAndGroupsTable .ag-row:hover {
  background-color: rgba(132, 201, 255, 0.1) !important; }

.usersAndGroupsTable .ag-center-cols-viewport {
  overflow-x: hidden; }

.usersAndGroupsTable .justifyContent {
  justify-content: center; }

.usersAndGroupsTable .tableListPagination .title {
  display: inline-block; }
