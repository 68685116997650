.selectInfiniteScroll .form-control {
  height: 32px;
  padding: 6px 10px 6px 14px;
  font-size: 12px; }

.selectInfiniteScroll .menuArrow svg {
  width: 15px;
  height: 15px; }

.selectInfiniteScroll .placeholder {
  color: #b8b8b8; }

.selectInfiniteScroll .error {
  font-size: 10px;
  color: #d84748; }

.selectInfiniteScroll .dropdown {
  user-select: none;
  cursor: pointer; }
  .selectInfiniteScroll .dropdown .selectLabel {
    width: 95%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.selectInfiniteScroll .dropdown-menu {
  cursor: default;
  width: 100%;
  padding: 0px 8px !important;
  max-height: 250px; }
  .selectInfiniteScroll .dropdown-menu .right-inner-addon i {
    margin: 14px;
    padding: 0px; }
  .selectInfiniteScroll .dropdown-menu input {
    margin: 8px 0px;
    width: 100% !important; }
  .selectInfiniteScroll .dropdown-menu .item {
    font-size: 12px;
    cursor: pointer;
    color: #333333;
    padding: 8px 0px; }
    .selectInfiniteScroll .dropdown-menu .item:hover {
      background-color: #eff7ff; }
