.topApps {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 10px;
  background-color: #ffffff; }
  .topApps a,
  .topApps a:hover,
  .topApps a:active {
    color: #006fcc; }
  .topApps .topAppsHeader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex; }
    .topApps .topAppsHeader .dropdown {
      cursor: pointer;
      display: inline-block; }
      .topApps .topAppsHeader .dropdown .dropdown-toggle {
        color: #006fcc !important;
        border: none;
        padding: 0px 8px;
        font-size: 16px; }
      .topApps .topAppsHeader .dropdown .dropdown-toggle:hover,
      .topApps .topAppsHeader .dropdown .dropdown-toggle:active,
      .topApps .topAppsHeader .dropdown .dropdown-toggle:focus,
      .topApps .topAppsHeader .dropdown .btn-default:focus,
      .topApps .topAppsHeader .dropdown .btn-default:active,
      .topApps .topAppsHeader .dropdown .open > .dropdown-toggle.btn-default {
        background-color: #fff !important;
        color: #1f75b2 !important;
        text-decoration: underline; }
      .topApps .topAppsHeader .dropdown .caret {
        color: #333333; }
      .topApps .topAppsHeader .dropdown li {
        color: #333333;
        padding: 8px;
        cursor: pointer;
        font-size: 14px; }
        .topApps .topAppsHeader .dropdown li:hover {
          background-color: #eff7ff; }
        .topApps .topAppsHeader .dropdown li.selectedItem, .topApps .topAppsHeader .dropdown li.selectedItem:hover {
          background-color: #d3e8fc; }
    .topApps .topAppsHeader .toggleTable {
      width: 18px;
      height: 25px;
      padding-bottom: 7px; }
    .topApps .topAppsHeader .helperLink {
      font-size: 12px;
      cursor: pointer;
      color: #1f75b2;
      padding-left: 16px; }
    .topApps .topAppsHeader .inactiveTableSelector {
      opacity: 0.2; }
  .topApps .saasVisibilitySanctionStatusBar {
    padding: 12px 0px;
    display: flex;
    align-items: center; }
    .topApps .saasVisibilitySanctionStatusBar .securityRules .dropdown-content .action {
      font-size: 12px;
      cursor: pointer;
      color: #333;
      padding: 8px 16px; }
      .topApps .saasVisibilitySanctionStatusBar .securityRules .dropdown-content .action:hover {
        background-color: #eff8ff; }
    .topApps .saasVisibilitySanctionStatusBar .securityRules .dropdown {
      cursor: default !important; }
      .topApps .saasVisibilitySanctionStatusBar .securityRules .dropdown .dropdown-toggle {
        cursor: pointer; }
    .topApps .saasVisibilitySanctionStatusBar .nameSection {
      padding-right: 0px;
      padding-left: 0px; }
    .topApps .saasVisibilitySanctionStatusBar .barName {
      cursor: pointer;
      font-size: 14px;
      padding-right: 0px;
      padding-left: 6px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      .topApps .saasVisibilitySanctionStatusBar .barName .dropdown-menu-center {
        right: auto; }
    .topApps .saasVisibilitySanctionStatusBar .riskScore {
      padding: 0px 0px 0px 8px;
      display: flex;
      justify-content: center; }
    .topApps .saasVisibilitySanctionStatusBar .barSvg {
      padding-left: 8px; }
      .topApps .saasVisibilitySanctionStatusBar .barSvg svg.sanctionBar {
        cursor: pointer;
        border-radius: 2px; }
      .topApps .saasVisibilitySanctionStatusBar .barSvg .barCount {
        display: inline-block;
        color: #4a4a4a;
        font-size: 14px;
        padding-left: 8px; }
  .topApps .ag-theme-balham .lowIconOne {
    background-color: #28c2a6;
    color: #fff;
    margin: 5px;
    padding: 2px 6px;
    border-radius: 10px; }
  .topApps .ag-theme-balham .lowIcon {
    background-color: #28c2a6;
    color: #fff;
    margin: 5px;
    padding: 2px 5.7px;
    border-radius: 10px; }
  .topApps .ag-theme-balham .mediumIcon {
    background-color: #fbd241;
    color: #000;
    margin: 5px;
    padding: 2px 5.6px;
    border-radius: 10px; }
  .topApps .ag-theme-balham .highIcon {
    background-color: #c83a4b;
    color: #fff;
    margin: 5px;
    padding: 2px 5px;
    border-radius: 10px; }
  .topApps .ag-theme-balham .highIconTen {
    background-color: #c83a4b;
    color: #fff;
    margin: 5px;
    padding: 2px 2px;
    border-radius: 10px; }
  .topApps .ag-root {
    border-color: #d4d4d4 !important;
    border-style: solid !important;
    border-width: 1px 0px !important; }
  .topApps .ag-header {
    border-bottom: 1px solid #d4d4d4 !important;
    background-color: #fff !important;
    color: #333333 !important; }
  .topApps .ag-header-cell {
    background-color: #fff !important; }
  .topApps .ag-header-cell-text {
    flex: 1;
    color: #333333; }
  .topApps .ag-cell {
    cursor: default !important;
    color: #333333 !important; }
  .topApps .ag-row {
    border: 0 !important; }
  .topApps .ag-row-odd {
    background-color: #fafafa !important; }
  .topApps .ag-row-even {
    background-color: #fff !important; }
  .topApps .ag-row-hover,
  .topApps .ag-row:hover {
    background-color: rgba(132, 201, 255, 0.1) !important; }
  .topApps .ag-center-cols-viewport {
    overflow-x: hidden; }
  .topApps [col-id="application.confidence"],
  .topApps [col-id="application.sanctionedStatus"] {
    text-align: center; }
  .topApps .headerText {
    color: #333333;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    display: flex; }
  .topApps .buttonGroupToggles {
    display: flex;
    align-items: flex-end; }
    .topApps .buttonGroupToggles .activeSelector {
      border-bottom: 3px solid #42a5ed;
      padding: 0; }
    .topApps .buttonGroupToggles .inactiveSelector {
      border-bottom: 3px solid #eaeaea;
      padding: 0;
      cursor: pointer; }
  .topApps .topAppsBody {
    padding: 16px 0px; }
  .topApps .topAppsFooter {
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px; }
    .topApps .topAppsFooter .riskLegendItem {
      display: flex;
      align-items: center;
      vertical-align: middle;
      padding: 0;
      user-select: none; }
    .topApps .topAppsFooter .inactiveItem {
      opacity: 0.3; }
    .topApps .topAppsFooter .resetFilter {
      font-size: 12px; }
  .topApps .noData {
    padding: 25px; }
  .topApps .sanctionedIcon {
    background-color: #1f75b2;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    margin: 5px;
    display: inline; }
  .topApps .unsanctionedIcon {
    background-color: #5db8e4;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    margin: 5px;
    display: inline; }
  .topApps .unknownIcon {
    background-color: #bce4fa;
    padding: 0px 7.2px;
    border-radius: 10px;
    margin: 5px;
    display: inline; }
  .topApps .__react_component_tooltip {
    opacity: 1 !important;
    max-width: 500px;
    word-break: break-all; }
  .topApps .__react_component_tooltip.type-light.border {
    border: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important; }
  .topApps .__react_component_tooltip.type-light.border.place-top:before {
    border-top: 8px solid rgba(0, 0, 0, 0.1); }
