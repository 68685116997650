.tableList {
  padding: 0px !important;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); }
  .tableList .ag-theme-balham .ag-header {
    border-bottom: 1px solid #d4d4d4;
    background-color: #fff;
    color: #333333; }
  .tableList .ag-theme-balham .ag-icon-checkbox-checked {
    color: #1f75b2; }
  .tableList .ag-theme-balham .ag-icon-checkbox-unchecked {
    color: #a3a3a3;
    line-height: 18px; }
  .tableList .ag-root {
    border-color: #d4d4d4 !important;
    border-style: solid !important;
    border-width: 1px 0px !important;
    overflow: visible !important; }
  .tableList .ag-body-viewport {
    max-height: 638px;
    overflow-y: scroll !important;
    overflow-x: hidden !important; }
  .tableList .ag-center-cols-container {
    overflow: hidden; }
  .tableList .AgGridLoading {
    display: flex;
    height: 4px; }
  .tableList .ag-header-cell {
    background-color: #fff !important; }
  .tableList .ag-header-cell-text {
    flex: 1;
    color: #333333; }
  .tableList .ag-cell {
    cursor: default !important;
    color: #333333 !important;
    overflow: visible; }
  .tableList .ag-row {
    border: 0 !important;
    z-index: 0; }
    .tableList .ag-row.ag-row-focus {
      z-index: 1; }
  .tableList .ag-row-odd {
    background-color: #fafafa !important; }
  .tableList .ag-row-even {
    background-color: #fff !important; }
  .tableList .ag-row-hover,
  .tableList .ag-row:hover {
    background-color: rgba(132, 201, 255, 0.1) !important; }
  .tableList .ag-body-horizontal-scroll,
  .tableList .ag-body-horizontal-scroll-container {
    overflow-x: none !important;
    display: none !important; }
