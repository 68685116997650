.caption {
  display: none; }

.table-container {
  margin: 1em auto;
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  font-style: normal;
  font-weight: normal; }
  .table-container .table-bordered {
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    border-collapse: collapse; }
  .table-container.table {
    border-collapse: collapse; }
    .table-container.table tbody {
      max-height: 663px;
      overflow-y: scroll; }
      .table-container.table tbody tr.expansion-hide {
        display: none; }
    .table-container.table .tbody {
      background-color: #ffffff;
      border-collapse: collapse;
      color: #333333;
      display: flex;
      flex-direction: column; }
      .table-container.table .tbody .tr {
        flex: 1; }
        .table-container.table .tbody .tr.expansion-hide {
          display: none; }
    .table-container.table .odd {
      background-color: #fafafa; }
    .table-container.table .td {
      border: none;
      padding: 0.5em;
      padding-left: 0.75em;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px; }
    .table-container.table .th {
      cursor: pointer;
      background: #ffffff;
      color: #333333;
      padding: 0.5em;
      border: 1px solid #d4d4d4;
      border-collapse: collapse;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-transform: capitalize; }
    .table-container.table .flex-row {
      width: 25%; }
    .table-container.table .flex-row,
    .table-container.table .flex-cell {
      text-align: left; }
    @media only screen and (max-width: 767px) {
      .table-container.table .table-container {
        display: block; }
      .table-container.table .th,
      .table-container.table .td {
        width: auto;
        display: block;
        border: 0; }
      .table-container.table .th {
        border-left: solid 1px #d4d4d4;
        border-right: solid 1px #d4d4d4;
        border-bottom: solid 1px #d4d4d4; }
      .table-container.table .td {
        border-left: solid 1px #d4d4d4;
        border-right: solid 1px #d4d4d4;
        border-bottom: solid 1px #d4d4d4; }
      .table-container.table .flex-row {
        width: 100%; } }
  .table-container div {
    box-sizing: border-box; }
  .table-container .thead,
  .table-container .tbody .tr {
    display: table;
    width: 100%;
    table-layout: fixed; }
  .table-container .expand-click {
    cursor: pointer; }
