.mainHeader {
  display: flex; }
  .mainHeader .leftTitle {
    display: flex;
    align-items: center;
    flex: 1 auto; }
  .mainHeader .centerTitle {
    flex: 1 auto;
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    display: flex; }
  .mainHeader .rightTitle {
    flex: 1 auto; }
    .mainHeader .rightTitle .applications-modal-button {
      width: 85px;
      color: #006fcc;
      font-size: 12px;
      float: right;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center; }
      .mainHeader .rightTitle .applications-modal-button img {
        height: 16px; }
