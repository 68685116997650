.riskScoringModal {
  height: 100%;
  width: 100%;
  position: fixed;
  left: auto;
  margin: auto;
  z-index: 2;
  color: #333333;
  font-size: 12px;
  overflow-y: none;
  left: 0px;
  top: 0px; }
  .riskScoringModal .modalBackdrop {
    background: #1f2733;
    opacity: 0.8;
    position: fixed;
    height: 100%;
    width: 100%; }
  .riskScoringModal .modalDialog {
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    margin: auto;
    top: 20%; }
    .riskScoringModal .modalDialog .disabledAttribute {
      user-select: none;
      pointer-events: none;
      opacity: 0.5; }
    .riskScoringModal .modalDialog.scoring {
      width: 85%;
      min-height: 252px; }
    .riskScoringModal .modalDialog.confirmation {
      width: 25%;
      height: 190px; }
    .riskScoringModal .modalDialog > .title {
      font-size: 16px;
      background-color: #fafafa;
      padding: 8px 16px;
      border-radius: 4px; }
      .riskScoringModal .modalDialog > .title .pull-right {
        display: flex; }
        .riskScoringModal .modalDialog > .title .pull-right .closeModal {
          cursor: pointer;
          color: #333333;
          margin-left: 20px; }
    .riskScoringModal .modalDialog .loadingIndicator {
      display: flex; }
    .riskScoringModal .modalDialog > .body {
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 12px 22px; }
      .riskScoringModal .modalDialog > .body.confirmation {
        padding-bottom: 10px; }
      .riskScoringModal .modalDialog > .body .defaultSettings {
        font-size: 16px;
        padding: 0px 0px 32px; }
      .riskScoringModal .modalDialog > .body .riskWeighting {
        display: flex; }
        .riskScoringModal .modalDialog > .body .riskWeighting .description {
          flex: 3;
          padding-bottom: 24px; }
        .riskScoringModal .modalDialog > .body .riskWeighting .customSetting {
          font-size: 12px;
          font-weight: bold; }
          .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .switch-sm {
            margin-left: 5px !important;
            margin-bottom: 1px !important;
            height: 16px;
            width: 32px; }
            .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .switch-sm .slider:before {
              height: 14px;
              width: 14px;
              bottom: 1px;
              left: 1px;
              top: 1px; }
            .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .switch-sm .on,
            .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .switch-sm .off,
            .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .switch-sm img {
              animation: fadeIn ease 0.8s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
            .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .switch-sm input:checked + .slider:before {
              -webkit-transform: translateX(16px);
              -ms-transform: translateX(16px);
              transform: translateX(16px); }
          .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .toggleEnabledIcon {
            width: 12px;
            height: 19px;
            position: relative;
            left: 8px;
            top: -4px; }
          .riskScoringModal .modalDialog > .body .riskWeighting .customSetting .toggleDisabledIcon {
            width: 12px;
            height: 19px;
            position: relative;
            top: -4px;
            left: -7px; }
      .riskScoringModal .modalDialog > .body .riskWeightingTotal .title {
        font-size: 16px; }
        .riskScoringModal .modalDialog > .body .riskWeightingTotal .title .toZero {
          cursor: pointer;
          text-align: center;
          display: inline-block;
          padding: 4px 16px;
          border-radius: 4px;
          min-width: 64px;
          font-size: 12px;
          background-color: #fff;
          color: #1f75b2;
          border: 1px solid #d4d4d4; }
        .riskScoringModal .modalDialog > .body .riskWeightingTotal .title .total {
          color: #c83a4b; }
          .riskScoringModal .modalDialog > .body .riskWeightingTotal .title .total.max {
            color: #28c2a6; }
      .riskScoringModal .modalDialog > .body .riskWeightingTotal .subtext {
        font-size: 10px;
        padding-bottom: 17px; }
      .riskScoringModal .modalDialog > .body .assigned {
        display: grid;
        grid-template-columns: repeat(5, 1fr); }
        .riskScoringModal .modalDialog > .body .assigned div {
          text-align: right;
          padding: 6px 20px; }
      .riskScoringModal .modalDialog > .body .attributesList {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 275px;
        padding: 0px 0px 24px 0px; }
        .riskScoringModal .modalDialog > .body .attributesList .attribute {
          display: flex;
          align-items: center;
          padding: 0px 16px;
          margin: 2px 8px; }
          .riskScoringModal .modalDialog > .body .attributesList .attribute:nth-child(n):nth-child(-n + 7):nth-child(even), .riskScoringModal .modalDialog > .body .attributesList .attribute:nth-child(n + 8):nth-child(-n + 14):nth-child(odd), .riskScoringModal .modalDialog > .body .attributesList .attribute:nth-child(n + 15):nth-child(-n + 21):nth-child(even), .riskScoringModal .modalDialog > .body .attributesList .attribute:nth-child(n + 22):nth-child(-n + 28):nth-child(odd), .riskScoringModal .modalDialog > .body .attributesList .attribute:nth-child(n + 29):nth-child(-n + 34):nth-child(even) {
            background-color: #fafafa; }
          .riskScoringModal .modalDialog > .body .attributesList .attribute .attributeName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 125px;
            padding-right: 2px; }
          .riskScoringModal .modalDialog > .body .attributesList .attribute .attributeInput {
            margin-left: auto; }
            .riskScoringModal .modalDialog > .body .attributesList .attribute .attributeInput input {
              width: 50px;
              text-align: right; }
    .riskScoringModal .modalDialog > .footer {
      background-color: #fafafa;
      text-align: right; }
      .riskScoringModal .modalDialog > .footer > div {
        cursor: pointer;
        text-align: center;
        display: inline-block;
        padding: 8px 10px;
        margin: 8px;
        border-radius: 4px;
        min-width: 64px; }
      .riskScoringModal .modalDialog > .footer .saving {
        opacity: 0.5;
        pointer-events: none; }
      .riskScoringModal .modalDialog > .footer > .cancel {
        background-color: #fff;
        color: #1f75b2;
        border: 1px solid #d4d4d4; }
      .riskScoringModal .modalDialog > .footer > .save {
        color: #fff;
        background-color: #c4c4c4;
        cursor: default; }
        .riskScoringModal .modalDialog > .footer > .save.disableSave {
          pointer-events: none; }
        .riskScoringModal .modalDialog > .footer > .save.allowSave {
          background-color: #1f75b2;
          cursor: pointer;
          border: 1px solid #d4d4d4; }
