.container {
  background-color: #f6f6f6;
  box-sizing: content-box;
  padding: 15px;
  display: flex;
  flex-direction: column; }
  .container .characteristics-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 15px; }
    .container .characteristics-container .characteristics-list {
      flex: 1 0 350px;
      padding: 10px;
      max-width: 350px; }
      .container .characteristics-container .characteristics-list .char-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 30px;
        padding: 5px; }
      .container .characteristics-container .characteristics-list .active {
        background-color: #ffffff; }
      .container .characteristics-container .characteristics-list .char-list-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        cursor: pointer; }
    .container .characteristics-container .characteristics-map {
      flex: 3 0 auto;
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 11px; }
      .container .characteristics-container .characteristics-map .total {
        position: absolute;
        right: 5%; }
      .container .characteristics-container .characteristics-map .description-box {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-right: 20px; }
        .container .characteristics-container .characteristics-map .description-box .search-box {
          position: relative;
          order: 2;
          flex: 1;
          padding-top: 5px; }
          .container .characteristics-container .characteristics-map .description-box .search-box input {
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 5px;
            width: 100%;
            height: 30px; }
          .container .characteristics-container .characteristics-map .description-box .search-box .search-icon {
            position: absolute;
            top: 9px;
            right: 10px; }
        .container .characteristics-container .characteristics-map .description-box .description {
          order: 1;
          flex: 3;
          padding: 8px 15px 0px 15px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 34px;
          /* or 283% */
          display: flex;
          align-items: center;
          /* Text/Alt Text */
          color: #333333; }
      .container .characteristics-container .characteristics-map title {
        display: flex;
        flex-direction: row;
        padding: 10px;
        justify-content: space-between;
        border-bottom: 1px solid #eaeaea; }
        .container .characteristics-container .characteristics-map title .title-name {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          /* Text/Primary & Body Text */
          color: #333333; }
      .container .characteristics-container .characteristics-map .attr-map {
        padding: 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        max-height: 250px;
        flex-wrap: wrap;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 34px;
        /* or 283% */
        display: flex;
        /* Text/Alt Text */
        color: #333333; }
        .container .characteristics-container .characteristics-map .attr-map .attr-container {
          display: flex;
          flex-direction: row;
          max-width: 250px;
          justify-content: space-between;
          height: 32px;
          align-items: center;
          margin-right: 25px;
          padding: 8px; }
          .container .characteristics-container .characteristics-map .attr-map .attr-container:nth-child(even) {
            background-color: #f6f6f6; }
          .container .characteristics-container .characteristics-map .attr-map .attr-container .attr-value {
            display: flex;
            justify-content: center;
            align-items: center; }
  .container .basic-info {
    flex: 1;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 25px;
    margin-right: 25px; }
    .container .basic-info .body {
      padding: 25px;
      padding-top: 5px;
      box-sizing: border-box;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 34px;
      align-items: center;
      color: #333333; }
      .container .basic-info .body .basic-attrs {
        display: flex;
        flex-direction: column;
        height: 150px;
        flex-wrap: wrap; }
        .container .basic-info .body .basic-attrs .attr-container {
          display: flex;
          justify-content: space-between;
          margin-right: 20px;
          width: 300px; }
          .container .basic-info .body .basic-attrs .attr-container .attr-name {
            flex: 1; }
          .container .basic-info .body .basic-attrs .attr-container .attr-display-name {
            color: #717171; }
          .container .basic-info .body .basic-attrs .attr-container .attr-value {
            flex: 1;
            justify-self: flex-end; }
          .container .basic-info .body .basic-attrs .attr-container .attr-value-truncated {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px; }
    .container .basic-info .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid #eaeaea; }
      .container .basic-info .title .suggestion-box {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #006fcc;
        visibility: hidden; }
  .container .attribute-map {
    flex: 1; }
  .container counter {
    display: flex;
    justify-content: flex-start;
    padding: 5px; }

.tooltip {
  max-width: 400px !important; }

.list-items {
  padding: 8px;
  list-style-type: none; }

.attr-content-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-right: 2px; }

.attr-value-truncated-list {
  display: inline-flex; }

.download-button {
  border: none;
  color: deepskyblue;
  background: #fff !important; }
  .download-button svg {
    margin-right: 5px; }

.download-button:hover,
.download-button:focus {
  color: #007d98;
  text-decoration: underline; }

.item-icon {
  height: 16px;
  width: 16px;
  margin-right: 2px; }
