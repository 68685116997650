.topNavBar {
  position: fixed;
  z-index: 200;
  top: 0;
  height: 50px; }
  .topNavBar :global(.navbar-nav) {
    min-width: 0;
    display: flex;
    float: none; }
    .topNavBar :global(.navbar-nav) > li > a {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: uppercase;
      padding: 18px 15px;
      letter-spacing: 1px;
      height: 50px;
      line-height: 11px;
      color: #d0d0d0 !important; }
      .topNavBar :global(.navbar-nav) > li > a:hover {
        color: #ffffff !important; }
  .topNavBar .navbarNav {
    float: none !important;
    margin-bottom: 0px;
    background-color: transparent;
    border: 0px;
    min-height: 50px;
    max-height: 50px; }
    .topNavBar .navbarNav > :global(.active) > a, .topNavBar .navbarNav > :global(.active) > a:hover, .topNavBar .navbarNav > :global(.active) > a:focus, .topNavBar .navbarNav :global(.active.open) > a, .topNavBar .navbarNav :global(.active.open) > a:hover, .topNavBar .navbarNav :global(.active.open) > a:focus {
      background-color: #0883b9 !important;
      color: #ffffff !important; }
  .topNavBar :global(a#help_link) {
    padding: 2px 15px 0px;
    height: 50px; }
    .topNavBar :global(a#help_link) :global(.help_img) {
      margin-top: 13px;
      width: 18px;
      opacity: 0.5; }
  .topNavBar .navbarDefault {
    width: 100%;
    min-height: 50px;
    margin-bottom: 0px;
    background-color: transparent;
    border: 0px; }
    .topNavBar .navbarDefault .navbarToggle {
      margin-top: 9px;
      border: 0px; }
    .topNavBar .navbarDefault .navbarLinks .navbarDefaultLink {
      height: 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-bottom: 0px;
      background-color: transparent;
      border: 0px;
      min-height: 50px; }
    .topNavBar .navbarDefault .navbar-collapse-btn {
      display: flex; }
    .topNavBar .navbarDefault .mainNav {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%; }
