.customTagsDropdown {
  font-size: 12px;
  padding: 0 !important;
  min-width: 240px !important;
  max-width: 240px;
  max-height: 345px;
  overflow-y: scroll;
  overflow-x: hidden; }
  .customTagsDropdown .searchTags {
    padding: 8px; }
    .customTagsDropdown .searchTags input {
      width: 100% !important; }
  .customTagsDropdown .ASVCustomTagging {
    font-size: 12px; }
    .customTagsDropdown .ASVCustomTagging .svgButton {
      background-color: #fff;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 5px;
      padding: 8px;
      height: 30px; }
    .customTagsDropdown .ASVCustomTagging .disableListItem {
      pointer-events: none;
      opacity: 0.7; }
    .customTagsDropdown .ASVCustomTagging .addNewTag {
      display: flex;
      justify-content: space-between;
      max-height: 36px;
      margin: 2px 8px 8px 8px; }
      .customTagsDropdown .ASVCustomTagging .addNewTag button {
        background-color: #fff;
        color: #1f75b2;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        padding: 0px 16px;
        width: 100%;
        height: 30px;
        margin: 0px 8px; }
        .customTagsDropdown .ASVCustomTagging .addNewTag button.disableAddTag {
          pointer-events: none;
          background-color: #e7e7e7;
          opacity: 0.4; }
      .customTagsDropdown .ASVCustomTagging .addNewTag .addNewTagPlus {
        display: flex;
        align-items: center;
        vertical-align: middle; }
        .customTagsDropdown .ASVCustomTagging .addNewTag .addNewTagPlus svg {
          background-color: #1f75b2;
          border-radius: 6px;
          padding: 1px;
          margin-right: 8px; }
    .customTagsDropdown .ASVCustomTagging .hideTag {
      display: none; }
    .customTagsDropdown .ASVCustomTagging .customTagList li {
      display: flex;
      justify-content: space-between;
      padding: 0px 8px 0px 16px;
      max-height: 30px; }
      .customTagsDropdown .ASVCustomTagging .customTagList li:hover {
        background-color: #eff7ff; }
      .customTagsDropdown .ASVCustomTagging .customTagList li label {
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .customTagsDropdown .ASVCustomTagging .customTagList li label input {
          vertical-align: middle;
          margin: 0px 8px 0px 0px; }
      .customTagsDropdown .ASVCustomTagging .customTagList li .editButton {
        margin-right: 4px;
        display: flex;
        align-items: center;
        padding: 0px 6px; }
        .customTagsDropdown .ASVCustomTagging .customTagList li .editButton:hover {
          background: #d3e8fc;
          border-radius: 4px; }
      .customTagsDropdown .ASVCustomTagging .customTagList li .deleteButton {
        margin-left: 4px;
        display: flex;
        align-items: center;
        padding: 0px 6px; }
        .customTagsDropdown .ASVCustomTagging .customTagList li .deleteButton:hover {
          background: #d3e8fc;
          border-radius: 4px; }
      .customTagsDropdown .ASVCustomTagging .customTagList li .tagCount {
        color: #808080;
        display: flex;
        align-items: center; }
