.topCategoriesByApp {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 10px;
  background-color: #ffffff; }
  .topCategoriesByApp .tooltip-inner {
    background-color: white !important;
    color: #252733 !important;
    font-size: 14px !important;
    border: 0.5px solid #dfe0eb !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
    padding: 12px 32px !important; }
  .topCategoriesByApp .tooltip.in {
    opacity: 1 !important;
    border-radius: 4px !important; }
  .topCategoriesByApp .tooltip.top .tooltip-arrow {
    border-top-color: #ccc !important; }
  .topCategoriesByApp .topCategoriesByAppHeader {
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: flex;
    align-items: center; }
    .topCategoriesByApp .topCategoriesByAppHeader .dropdown {
      display: inline-block; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .dropdown-toggle {
        color: #1f75b2 !important;
        border: none;
        padding: 0px 8px;
        font-size: 16px; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .dropdown-toggle:hover,
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .dropdown-toggle:active,
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .dropdown-toggle:focus,
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .btn-default:focus,
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .btn-default:active,
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .open > .dropdown-toggle.btn-default {
        background-color: #fff !important;
        color: #1f75b2 !important;
        text-decoration: underline; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown .caret {
        color: #333333; }
      .topCategoriesByApp .topCategoriesByAppHeader .dropdown li {
        color: #333333;
        padding: 8px;
        cursor: pointer;
        font-size: 14px; }
        .topCategoriesByApp .topCategoriesByAppHeader .dropdown li:hover {
          background-color: #eff7ff; }
        .topCategoriesByApp .topCategoriesByAppHeader .dropdown li.selectedItem, .topCategoriesByApp .topCategoriesByAppHeader .dropdown li.selectedItem:hover {
          background-color: #d3e8fc; }
  .topCategoriesByApp .topCategoriesBody {
    padding: 16px 0px; }
  .topCategoriesByApp .topCategoriesByAppFooter {
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px; }
    .topCategoriesByApp .topCategoriesByAppFooter .riskLegendItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      vertical-align: middle;
      padding: 0;
      user-select: none; }
    .topCategoriesByApp .topCategoriesByAppFooter .inactiveItem {
      opacity: 0.3; }
    .topCategoriesByApp .topCategoriesByAppFooter .resetFilter {
      font-size: 12px;
      color: #1f75b2 !important; }
  .topCategoriesByApp .noData {
    padding: 25px; }
  .topCategoriesByApp .saasVisibilityTopCategoriesBar {
    padding: 8px 16px;
    display: flex;
    align-items: center; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar svg {
      cursor: pointer; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar .nameSection {
      padding-right: 0px;
      padding-left: 0px; }
      .topCategoriesByApp .saasVisibilityTopCategoriesBar .nameSection .iconCategory {
        margin-right: 8px;
        height: 16px;
        width: 16px;
        vertical-align: bottom; }
      .topCategoriesByApp .saasVisibilityTopCategoriesBar .nameSection .barName {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 16px; }
        .topCategoriesByApp .saasVisibilityTopCategoriesBar .nameSection .barName svg {
          margin-right: 8px;
          height: 16px;
          width: 16px;
          vertical-align: bottom; }
        .topCategoriesByApp .saasVisibilityTopCategoriesBar .nameSection .barName .dropdown-menu-center {
          right: auto; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar .barGraph {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 6px;
      line-height: 16px; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar .fullRadius {
      border-radius: 2px 2px 2px 2px; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar .startRadius {
      border-radius: 2px 0px 0px 2px; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar .endRadius {
      border-radius: 0px 2px 2px 0px; }
    .topCategoriesByApp .saasVisibilityTopCategoriesBar .barCount {
      color: #4a4a4a;
      font-size: 14px;
      padding-left: 8px; }
