.confidenceSanctionedSection {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  padding: 0 0 16px 0 !important; }

.advancedSaasVisibilityConfidenceSanctionedSection {
  padding: 16px 12px; }
  .advancedSaasVisibilityConfidenceSanctionedSection .barLabel {
    font-weight: normal;
    padding-left: 0px !important;
    padding-right: 8px !important; }
  .advancedSaasVisibilityConfidenceSanctionedSection .barSvg {
    padding: 0 !important;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1)); }
  .advancedSaasVisibilityConfidenceSanctionedSection .barCount {
    pointer-events: none;
    padding-right: 0px !important;
    padding-left: 8px !important; }
  .advancedSaasVisibilityConfidenceSanctionedSection .confidenceSanctionBar {
    display: flex;
    align-items: center;
    padding: 5px 0px; }
  .advancedSaasVisibilityConfidenceSanctionedSection .confidenceSanctionBarSvg {
    display: flex;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 2px; }
  .advancedSaasVisibilityConfidenceSanctionedSection .barGroupTitle {
    font-size: 16px;
    color: #808080;
    padding: 15px 0px; }
  .advancedSaasVisibilityConfidenceSanctionedSection .tagsHeader {
    padding-bottom: 4px; }
