.AdvancedSaasVisibilityNewRule {
  font-family: Lato;
  font-style: normal;
  font-size: 12px;
  background: #ffff;
  /* header  */
  /* content rule */ }
  .AdvancedSaasVisibilityNewRule a,
  .AdvancedSaasVisibilityNewRule a:hover,
  .AdvancedSaasVisibilityNewRule a:active {
    color: #006fcc; }
  .AdvancedSaasVisibilityNewRule .newRuleHeader {
    padding: 12px 0px;
    background-color: #ffffff; }
    .AdvancedSaasVisibilityNewRule .newRuleHeader .breadcrumbNav {
      font-size: 12px; }
      .AdvancedSaasVisibilityNewRule .newRuleHeader .breadcrumbNav .arrow {
        color: #333333;
        padding: 0px 8px 0px 16px;
        font-size: 9px; }
      .AdvancedSaasVisibilityNewRule .newRuleHeader .breadcrumbNav .current {
        color: #333333; }
  .AdvancedSaasVisibilityNewRule .headerTitle {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 0px !important; }
  .AdvancedSaasVisibilityNewRule .headerText {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    padding-top: 5px;
    display: flex;
    align-items: flex-end;
    background-color: #ffffff; }
  .AdvancedSaasVisibilityNewRule .headerSubtext {
    color: #707070;
    font-size: 12px;
    padding-bottom: 9px;
    background-color: #ffffff; }
  .AdvancedSaasVisibilityNewRule .contentRule {
    margin-top: 24px;
    display: flex; }
    .AdvancedSaasVisibilityNewRule .contentRule .leftSections {
      width: 400px;
      float: left;
      margin-right: 22px; }
      .AdvancedSaasVisibilityNewRule .contentRule .leftSections .sectionBox {
        margin-bottom: 15px; }
    .AdvancedSaasVisibilityNewRule .contentRule .rightSections {
      flex: 1 auto;
      min-width: 400px;
      display: flex;
      flex-direction: column; }
      .AdvancedSaasVisibilityNewRule .contentRule .rightSections .applicationsSection {
        max-height: 475px; }
      .AdvancedSaasVisibilityNewRule .contentRule .rightSections .sectionBox {
        background: #ffffff;
        border: 1px solid #dadbdb;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        flex: 1 auto;
        margin-bottom: 15px; }
        .AdvancedSaasVisibilityNewRule .contentRule .rightSections .sectionBox .content {
          flex: 1 auto;
          display: flex;
          align-items: center;
          justify-content: center; }
      .AdvancedSaasVisibilityNewRule .contentRule .rightSections .bottomRightSection {
        display: flex; }
        .AdvancedSaasVisibilityNewRule .contentRule .rightSections .bottomRightSection .DevicePostureDataProfileGroup {
          width: 400px; }
          .AdvancedSaasVisibilityNewRule .contentRule .rightSections .bottomRightSection .DevicePostureDataProfileGroup .devicePostureSection,
          .AdvancedSaasVisibilityNewRule .contentRule .rightSections .bottomRightSection .DevicePostureDataProfileGroup .dataProfileSection {
            width: 400px; }
  .AdvancedSaasVisibilityNewRule .formButtonSection {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: right; }
    .AdvancedSaasVisibilityNewRule .formButtonSection .cancelForm {
      margin-right: 10px; }
    .AdvancedSaasVisibilityNewRule .formButtonSection .saveForm {
      background-color: #006fcc !important; }
