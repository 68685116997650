@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.applicationCellRenderer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.categoryCellRenderer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.confidenceCellRenderer {
  display: flex; }

.tagCellRenderer svg {
  cursor: pointer;
  display: flex; }

.tagCellRenderer .dropdown {
  display: inline-block;
  margin: 2px 8px 0px 0px;
  padding: 4px; }
  .tagCellRenderer .dropdown:hover {
    background-color: #d3e8fc;
    border-radius: 4px; }

.tagCellRenderer .dropdown-menu div[role="button"] {
  padding: 8px; }
  .tagCellRenderer .dropdown-menu div[role="button"]:hover {
    background-color: #eff7ff; }

.tagCellRenderer .dropdown-menu .sanctionedIcon {
  background-color: #1f75b2;
  color: #fff;
  border-radius: 2px;
  padding: 2px 6px;
  margin: 5px;
  display: inline; }

.tagCellRenderer .dropdown-menu .toleratedIcon {
  background-color: #282f78;
  color: #fff;
  border-radius: 2px;
  padding: 2px 6px;
  margin: 5px;
  display: inline; }

.tagCellRenderer .dropdown-menu .unsanctionedIcon {
  background-color: #5db8e4;
  color: #fff;
  border-radius: 2px;
  padding: 2px 5px;
  margin: 5px;
  display: inline; }

.tagCellRenderer .dropdown-menu .unknownIcon {
  background-color: #bce4fa;
  padding: 0px 5.6px;
  border-radius: 10px;
  margin: 7px;
  display: inline; }

.tagCellRenderer .dropdown-menu label {
  cursor: pointer;
  color: #333333;
  padding: 0px;
  margin: 3px 0px;
  font-weight: 400;
  font-size: 12px;
  width: 100%; }
  .tagCellRenderer .dropdown-menu label input[type="checkbox"] {
    margin-right: 8px; }

.tagCellRenderer .securityRules .dropdown-content .action {
  font-size: 12px;
  cursor: pointer;
  color: #333;
  padding: 8px 16px; }
  .tagCellRenderer .securityRules .dropdown-content .action:hover {
    background-color: #eff8ff; }

.tagCellRenderer .securityRules .dropdown {
  cursor: default !important; }
  .tagCellRenderer .securityRules .dropdown .dropdown-toggle {
    cursor: pointer; }

.userCellRenderer .userValue {
  top: 1px;
  position: relative; }

.customTagsCellRenderer {
  display: flex; }
  .customTagsCellRenderer .customTags {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis; }
  .customTagsCellRenderer .plusMore {
    flex: 0 0 auto; }
    .customTagsCellRenderer .plusMore .plus {
      color: #1f75b2; }

.ag-header-cell[col-id="confidence"] .ag-react-container {
  width: 100%;
  text-align: left; }

.riskHeaderRenderer > .title,
.datetimeHeaderRenderer > .title,
.ruleSyncedHeaderRenderer > .title {
  padding: 2px 4px 0px 0px; }

.riskHeaderRenderer > .helperIcon,
.datetimeHeaderRenderer > .helperIcon,
.ruleSyncedHeaderRenderer > .helperIcon {
  padding-left: 4px; }

.riskHeaderRenderer > .ag-icon,
.datetimeHeaderRenderer > .ag-icon,
.ruleSyncedHeaderRenderer > .ag-icon {
  display: inline-block !important; }

.riskHeaderRenderer .helperIcon,
.datetimeHeaderRenderer .helperIcon,
.ruleSyncedHeaderRenderer .helperIcon {
  font-size: 12px;
  cursor: pointer;
  color: #1f75b2;
  padding-left: 4px;
  position: relative;
  top: -2px; }

.ruleCheckIcon {
  width: 22px;
  height: 29px; }

.activityCheckIcon {
  width: 14px;
  height: 29px; }

.rulesCellRenderer {
  overflow: visible; }
  .rulesCellRenderer .dropbtn {
    color: #006fcc; }
  .rulesCellRenderer .hoverableDropdown {
    position: relative;
    display: inline-block;
    background-color: inherit; }
  .rulesCellRenderer .dropdown-content {
    display: none;
    position: absolute;
    height: 10px;
    min-width: 225px;
    z-index: 5; }
  .rulesCellRenderer .dropdown-content .rule {
    cursor: pointer;
    color: #006fcc;
    border-bottom: 1px solid #eaeaea;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .rulesCellRenderer .dropdown-content .rule:last-of-type {
      border-bottom: none; }
  .rulesCellRenderer .hoverableDropdown:hover .dropdown-content {
    display: block; }
  .rulesCellRenderer div.tooltipMenu {
    position: relative;
    display: inline;
    overflow: visible; }
  .rulesCellRenderer div.tooltipMenu span {
    overflow: visible;
    bottom: 38px;
    right: 48%;
    position: absolute;
    width: 225px;
    background: #fff;
    visibility: visible;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #eaebeb; }
  .rulesCellRenderer div.tooltipMenu span:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent; }

.tagIconHeaderRenderer {
  justify-content: center; }

/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 5px; }

.switch input {
  display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #707070;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #38a5ff; }

input:focus + .slider {
  box-shadow: 0 0 1px #38a5ff; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.slider.disabled {
  cursor: not-allowed; }

.switch-sm {
  height: 16px;
  width: 32px; }
  .switch-sm .slider:before {
    height: 14px;
    width: 14px;
    bottom: 1px;
    left: 1px;
    top: 1px; }
  .switch-sm input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px); }

.ruleEnabledIcon {
  width: 12px;
  height: 19px;
  position: relative;
  left: 8px;
  top: -9px; }

.ruleDisabledIcon {
  width: 12px;
  height: 19px;
  position: relative;
  top: -9px;
  left: -7px; }

.securityRuleActions {
  line-height: 48px; }
  .securityRuleActions .changeStatusRule {
    display: inline-block;
    text-align: center; }
    .securityRuleActions .changeStatusRule .enabledText {
      display: inline-block;
      margin-right: 10px;
      width: 45px; }
    .securityRuleActions .changeStatusRule .switch {
      line-height: 26px; }
    .securityRuleActions .changeStatusRule .ruleEnabledIcon,
    .securityRuleActions .changeStatusRule .ruleDisabledIcon,
    .securityRuleActions .changeStatusRule .on,
    .securityRuleActions .changeStatusRule .off {
      animation: fadeIn ease 0.8s; }
  .securityRuleActions .deleteRule {
    display: inline-block; }
    .securityRuleActions .deleteRule .btnDeleteRule {
      border: 0;
      background: transparent;
      display: block;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px; }
      .securityRuleActions .deleteRule .btnDeleteRule svg {
        display: inline-block;
        vertical-align: middle; }
    .securityRuleActions .deleteRule .btnDeleteRule:hover {
      background-color: #cfe8fc; }
  .securityRuleActions .editRule {
    margin-left: 30px; }
  .securityRuleActions .editRule,
  .securityRuleActions .cloneRule {
    margin-right: 8px;
    display: inline-block; }
    .securityRuleActions .editRule .btnEditRule,
    .securityRuleActions .editRule .btnCloneRule,
    .securityRuleActions .cloneRule .btnEditRule,
    .securityRuleActions .cloneRule .btnCloneRule {
      display: block;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px; }
    .securityRuleActions .editRule .btnEditRule:hover,
    .securityRuleActions .editRule .btnCloneRule:hover,
    .securityRuleActions .cloneRule .btnEditRule:hover,
    .securityRuleActions .cloneRule .btnCloneRule:hover {
      background-color: #cfe8fc; }
    .securityRuleActions .editRule svg,
    .securityRuleActions .cloneRule svg {
      display: inline-block;
      vertical-align: middle; }

.ruleNameCellRenderer .editRuleLink {
  color: #006fcc; }

.ruleNameCellRenderer .defaultRule {
  font-weight: bold; }
