.appsByRisk {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 10px;
  background-color: #ffffff; }
  .appsByRisk a,
  .appsByRisk a:hover,
  .appsByRisk a:active {
    color: #337ab7; }
  .appsByRisk .appsByRiskHeader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex; }
    .appsByRisk .appsByRiskHeader .headerText {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center; }
    .appsByRisk .appsByRiskHeader .buttonGroupToggles {
      display: flex;
      align-items: flex-end;
      padding: 0px 0px 0px 0px; }
      .appsByRisk .appsByRiskHeader .buttonGroupToggles .toggleTable {
        width: 18px;
        height: 25px;
        padding-bottom: 7px; }
      .appsByRisk .appsByRiskHeader .buttonGroupToggles .activeSelector {
        border-bottom: 3px solid #42a5ed;
        padding: 0; }
      .appsByRisk .appsByRiskHeader .buttonGroupToggles .inactiveSelector {
        border-bottom: 3px solid #eaeaea;
        padding: 0;
        cursor: pointer; }
      .appsByRisk .appsByRiskHeader .buttonGroupToggles [role="button"]:focus {
        outline: none; }
  .appsByRisk .inactiveTableSelector {
    opacity: 0.2; }
  .appsByRisk .helperLink {
    font-size: 12px;
    cursor: pointer;
    color: #006fcc;
    padding-left: 16px; }
  .appsByRisk .ASVTooltip {
    opacity: 1 !important; }
    .appsByRisk .ASVTooltip .helperTextTooltip {
      font-size: 12px;
      color: #717171; }
      .appsByRisk .ASVTooltip .helperTextTooltip .descriptionText {
        padding: 5px 20px; }
      .appsByRisk .ASVTooltip .helperTextTooltip .header {
        color: #000; }
      .appsByRisk .ASVTooltip .helperTextTooltip .noRisk {
        color: #28c2a6; }
      .appsByRisk .ASVTooltip .helperTextTooltip .maxRisk {
        color: #c83a4b; }
  .appsByRisk .appsByRiskPieChart {
    padding: 35px; }
    .appsByRisk .appsByRiskPieChart svg a:hover {
      text-decoration: none; }
  .appsByRisk .pieChartCount {
    cursor: pointer; }
  .appsByRisk .arc {
    cursor: pointer; }
  .appsByRisk .opaque {
    opacity: 1; }
  .appsByRisk .transparent {
    opacity: 0.2; }
  .appsByRisk .tooltip {
    border: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important;
    opacity: 1 !important; }
  .appsByRisk .tooltip:before {
    border-top: 8px solid rgba(0, 0, 0, 0.1) !important; }
  .appsByRisk .appsByRiskTableView {
    height: 395px;
    /* AG GRID COLORS */ }
    .appsByRisk .appsByRiskTableView :global(.ag-root) {
      border-color: #d4d4d4 !important;
      border-style: solid !important;
      border-width: 1px 0px !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-header) {
      border-bottom: 1px solid #d4d4d4 !important;
      background-color: #fff !important;
      color: #333333 !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-header-cell) {
      background-color: #fff !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-header-cell-text) {
      flex: 1;
      color: #333333; }
    .appsByRisk .appsByRiskTableView :global(.ag-cell) {
      cursor: default !important;
      color: #333333 !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-row) {
      border: 0 !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-row-odd) {
      background-color: #fafafa !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-row-even) {
      background-color: #fff !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-row-hover),
    .appsByRisk .appsByRiskTableView :global(.ag-row:hover) {
      background-color: rgba(132, 201, 255, 0.1) !important; }
    .appsByRisk .appsByRiskTableView :global(.ag-center-cols-viewport) {
      overflow-x: hidden; }
    .appsByRisk .appsByRiskTableView :global(.justifyContent) {
      justify-content: center; }
  .appsByRisk .appsByRiskFooter {
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px; }
    .appsByRisk .appsByRiskFooter .riskLegendItem {
      align-items: center;
      vertical-align: middle;
      padding: 0; }
      .appsByRisk .appsByRiskFooter .riskLegendItem .sanctionedIcon {
        padding: 2px 7px; }
      .appsByRisk .appsByRiskFooter .riskLegendItem .unknownIcon {
        padding: 0px 6.2px; }
    .appsByRisk .appsByRiskFooter .sanctionedIcon {
      background-color: #1f75b2;
      color: #fff;
      border-radius: 2px;
      padding: 2px 6.9px;
      display: inline; }
      .appsByRisk .appsByRiskFooter .sanctionedIcon.smallSanctionedIcon {
        padding: 0px 6px;
        margin: 0px 6px 0px 0px; }
    .appsByRisk .appsByRiskFooter .unsanctionedIcon {
      background-color: #5db8e4;
      color: #fff;
      border-radius: 2px;
      padding: 2px 6px;
      display: inline; }
      .appsByRisk .appsByRiskFooter .unsanctionedIcon.smallSanctionedIcon {
        padding: 0px 5px;
        margin: 0px 6px 0px 0px; }
    .appsByRisk .appsByRiskFooter .unknownIcon {
      background-color: #bce4fa;
      padding: 0px 7.2px;
      border-radius: 10px;
      display: inline;
      color: #bce4fa; }
      .appsByRisk .appsByRiskFooter .unknownIcon.smallSanctionedIcon {
        padding: 0px 4px;
        margin: 0px 6px 0px 0px; }
