.userNew {
  padding: 0;
  margin: 0; }
  .userNew :global(.page-header) {
    border-bottom: 0px;
    font-size: 24px;
    padding: 16px 16px 16px 16px;
    margin: 0px -20px 20px -20px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333333; }
    .userNew :global(.page-header) :global(h1) {
      margin-top: 0;
      margin-bottom: .25em;
      color: #333333;
      font-size: 30px; }
  .userNew :global(.panel) {
    border-radius: 1px; }
  .userNew :global(.form-button-row) {
    margin-top: 40px; }

.userForm :global(.nameField) {
  width: 282px;
  margin-right: 12px; }

.userForm :global(label) {
  font-size: 16px;
  font-weight: 400;
  color: #707070; }

.userForm :global(.form-button-row) {
  margin-top: 40px; }
  .userForm :global(.form-button-row) :global(.btn) {
    float: left;
    margin-right: 5px; }
