.deleteRuleModal :global(.modal-dialog) {
  width: 459px; }

.deleteRuleModal:global(.modal.in) {
  display: block; }

.deleteRuleModal :global(.modal-content .modal-header) {
  background-color: #F4F5F5 !important;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 6px 6px 0px 0px;
  padding: 8px 16px; }
  .deleteRuleModal :global(.modal-content .modal-header) :global(.modal-title) {
    color: #333333 !important;
    font-weight: 400 !important;
    font-size: 16px; }

.deleteRuleModal :global(.modal-content .modal-footer) {
  text-align: right !important;
  background: #FFFFFF;
  border-radius: 0px 0px 6px 6px; }
  .deleteRuleModal :global(.modal-content .modal-footer) :global(.btn-cancel) {
    color: #006FCC !important;
    border: 1px solid #DADBDB; }
  .deleteRuleModal :global(.modal-content .modal-footer) :global(.btn-apply) {
    background: #006FCC; }
  .deleteRuleModal :global(.modal-content .modal-footer) :global(.btn-cancel), .deleteRuleModal :global(.modal-content .modal-footer) :global(.btn-apply) {
    border-radius: 4px;
    height: 38px;
    width: 67px;
    font-size: 12px; }

.deleteRuleModal :global(.modal-content .modal-header), .deleteRuleModal :global(.modal-content .modal-footer),
.deleteRuleModal :global(.modal-content .modal-body) {
  padding-left: 18px;
  padding-right: 18px; }

.deleteRuleModal :global(.modal-body) {
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: 20px; }
  .deleteRuleModal :global(.modal-body) .warningQuestion {
    margin-top: 10px; }
  .deleteRuleModal :global(.modal-body) .editRuleLink {
    color: #006fcc; }
