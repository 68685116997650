.infoWidget {
  padding: 0px 8px; }
  .infoWidget .body {
    padding: 0;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    height: 250px;
    display: inline-block;
    color: #333;
    background-color: #ffffff; }
  .infoWidget .title {
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #eaeaea;
    padding: 8px 16px; }
  .infoWidget .count {
    font-size: 48px;
    font-weight: 300;
    text-align: center;
    padding: 16px 0px 4px 0px;
    line-height: 48px; }
    .infoWidget .count a:hover {
      text-decoration: none; }
  .infoWidget .text {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    padding: 0px 75px;
    line-height: 17px; }
  .infoWidget .image {
    text-align: center;
    padding: 24px 8px 12px 8px; }
    .infoWidget .image.twoLinePadding {
      padding-top: 8px; }
