.loadingWidget {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 0 !important;
  margin-top: 10px;
  background-color: #ffffff; }
  .loadingWidget .loadingWidgetHeader {
    border-bottom: 1px solid #EAEAEA;
    padding: 10px; }
  .loadingWidget .loadingWidgetBody {
    display: flex; }
  .loadingWidget .centerText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 79%; }
