.advancedSaasVisibilityRuleList {
  font-family: Lato;
  font-style: normal;
  background-color: #ffffff;
  position: relative;
  /* header              */ }
  .advancedSaasVisibilityRuleList .tooltip {
    border: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important;
    opacity: 1 !important; }
  .advancedSaasVisibilityRuleList .tooltip:before {
    border-top: 8px solid rgba(0, 0, 0, 0.1) !important; }
  .advancedSaasVisibilityRuleList .titleSection {
    display: inline-block; }
  .advancedSaasVisibilityRuleList .createRuleSection {
    margin-top: 17px;
    margin-right: 17px; }
    .advancedSaasVisibilityRuleList .createRuleSection .btn-primary {
      background: linear-gradient(180deg, #2686CB 0.89%, #1F75B2 100.89%); }
  .advancedSaasVisibilityRuleList .loadingIndicator {
    width: 100%;
    display: flex;
    position: relative; }
    .advancedSaasVisibilityRuleList .loadingIndicator .alignIndicator {
      position: absolute;
      width: 100%;
      top: -13px; }
  .advancedSaasVisibilityRuleList .disabledSection {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    opacity: 0.4;
    pointer-events: auto; }
  .advancedSaasVisibilityRuleList .headerText {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    padding-top: 5px;
    display: flex;
    align-items: flex-end; }
    .advancedSaasVisibilityRuleList .headerText .headerCountText {
      color: #333333;
      font-style: italic;
      font-size: 12px;
      padding-left: 4px;
      margin-bottom: 4px; }
    .advancedSaasVisibilityRuleList .headerText .betaTag {
      padding: 4px 11px;
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      background-color: #28c2a6;
      font-weight: bold;
      border-radius: 16px;
      margin: 8px; }
  .advancedSaasVisibilityRuleList .errorState {
    padding: 50px; }
  .advancedSaasVisibilityRuleList .headerSubtext {
    color: #707070;
    font-size: 12px;
    padding-bottom: 5px; }
  .advancedSaasVisibilityRuleList .headerTitle {
    z-index: 1;
    padding: 0px !important; }
  .advancedSaasVisibilityRuleList .ruleListTable {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #f6f6f6; }
  .advancedSaasVisibilityRuleList #securityRuleTableTooltip {
    width: 203px;
    height: 75px;
    vertical-align: middle;
    justify-content: left; }
  .advancedSaasVisibilityRuleList #securityRuleSyncedTooltip {
    width: 210px;
    height: 75px;
    vertical-align: middle;
    justify-content: left; }
  .advancedSaasVisibilityRuleList .__react_component_tooltip .dateTimeContentTooltip {
    width: 187px;
    height: 54px;
    vertical-align: middle;
    display: flex;
    align-items: center; }
  .advancedSaasVisibilityRuleList .__react_component_tooltip .syncedContentTooltip {
    width: 190px;
    height: 54px;
    vertical-align: middle;
    display: flex;
    align-items: center; }
