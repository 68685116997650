.tagIconHeaderRenderer {
  justify-content: center; }
  .tagIconHeaderRenderer :global(.sanctionedIcon) {
    background-color: #1f75b2;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    margin: 5px;
    display: inline; }
  .tagIconHeaderRenderer :global(.unsanctionedIcon) {
    background-color: #5db8e4;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    margin: 5px;
    display: inline; }
  .tagIconHeaderRenderer :global(.unknownIcon) {
    background-color: #bce4fa;
    padding: 0px 7.2px;
    border-radius: 10px;
    margin: 5px;
    display: inline; }
  .tagIconHeaderRenderer :global(.toleratedIcon) {
    background-color: #282f78;
    color: #fff;
    border-radius: 2px;
    padding: 2px 6px;
    margin: 5px;
    display: inline; }

.ruleNameCellRenderer .editRuleLink {
  color: #006fcc; }

.ruleNameCellRenderer .defaultRule {
  font-weight: bold; }

.riskHeaderRenderer > .title, .datetimeHeaderRenderer > .title,
.ruleSyncedHeaderRenderer > .title {
  padding: 2px 4px 0px 0px; }

.riskHeaderRenderer > .helperIcon, .datetimeHeaderRenderer > .helperIcon,
.ruleSyncedHeaderRenderer > .helperIcon {
  padding-left: 4px; }

.riskHeaderRenderer > .ag-icon, .datetimeHeaderRenderer > .ag-icon,
.ruleSyncedHeaderRenderer > .ag-icon {
  display: inline-block !important; }

.riskHeaderRenderer .helperIcon, .datetimeHeaderRenderer .helperIcon,
.ruleSyncedHeaderRenderer .helperIcon {
  font-size: 12px;
  cursor: pointer;
  color: #1f75b2;
  padding-left: 4px;
  position: relative;
  top: -2px; }
  .riskHeaderRenderer .helperIcon svg, .datetimeHeaderRenderer .helperIcon svg,
  .ruleSyncedHeaderRenderer .helperIcon svg {
    display: inline-block;
    vertical-align: middle; }

.ruleCheckIcon {
  width: 22px;
  height: 29px; }

.activityCheckIcon {
  width: 14px;
  height: 29px; }

/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: 5px; }
  .switch input {
    display: none; }
  .switch .on {
    display: none;
    float: left;
    margin-left: 10px; }
  .switch .off {
    float: right;
    margin-right: 10px; }
  .switch .on, .switch .off {
    color: #fff;
    font-size: 12px;
    margin-top: 3px; }
  .switch input:checked + .slider .on {
    display: block; }
  .switch input:checked + .slider .off {
    display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #707070;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #38a5ff; }

input:focus + .slider {
  box-shadow: 0 0 1px #38a5ff; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.slider.disabled {
  cursor: not-allowed; }

.switchSm {
  height: 16px;
  width: 32px; }
  .switchSm .slider:before {
    height: 14px;
    width: 14px;
    bottom: 1px;
    left: 1px;
    top: 1px; }
  .switchSm input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px); }

.ruleEnabledIcon {
  width: 12px;
  height: 19px;
  position: relative;
  left: 8px;
  top: -5px; }

.ruleDisabledIcon {
  width: 12px;
  height: 19px;
  position: relative;
  top: -5px;
  left: -7px; }

.securityRuleActions {
  line-height: 48px; }
  .securityRuleActions .changeStatusRule {
    display: inline-block;
    text-align: center; }
    .securityRuleActions .changeStatusRule .enabledText {
      display: inline-block;
      margin-right: 10px;
      width: 45px; }
    .securityRuleActions .changeStatusRule .switch {
      line-height: 26px; }
    .securityRuleActions .changeStatusRule .ruleEnabledIcon,
    .securityRuleActions .changeStatusRule .ruleDisabledIcon,
    .securityRuleActions .changeStatusRule .on,
    .securityRuleActions .changeStatusRule .off {
      animation: fadeIn ease 0.8s; }
  .securityRuleActions .deleteRule {
    display: inline-block; }
    .securityRuleActions .deleteRule .btnDeleteRule {
      border: 0;
      background: transparent;
      display: block;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px; }
      .securityRuleActions .deleteRule .btnDeleteRule svg {
        display: inline-block;
        vertical-align: middle; }
    .securityRuleActions .deleteRule .btnDeleteRule:hover {
      background-color: #cfe8fc; }
  .securityRuleActions .editRule {
    margin-left: 30px; }
  .securityRuleActions .editRule,
  .securityRuleActions .cloneRule {
    margin-right: 8px;
    display: inline-block; }
    .securityRuleActions .editRule .btnEditRule,
    .securityRuleActions .editRule .btnCloneRule,
    .securityRuleActions .cloneRule .btnEditRule,
    .securityRuleActions .cloneRule .btnCloneRule {
      display: block;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px; }
    .securityRuleActions .editRule .btnEditRule:hover,
    .securityRuleActions .editRule .btnCloneRule:hover,
    .securityRuleActions .cloneRule .btnEditRule:hover,
    .securityRuleActions .cloneRule .btnCloneRule:hover {
      background-color: #cfe8fc; }
    .securityRuleActions .editRule svg,
    .securityRuleActions .cloneRule svg {
      display: inline-block;
      vertical-align: middle; }
