/* VARIABLES
CirroSecure overrides to Bootstrap Variables */

/* --------------------------------------------------------------
  Colors
  Gray and brand colors for use across Bootstrap.
----------------------------------------------------------------- */


//$link-color from loop_color_palette_variables

$brand-primary:         #2aa6d0;
$brand-success:         #00BABC;

$gray-darker:           #444; // font color
$gray-dark:             #666;
$gray:                  #787878;
$gray-light:            #999; // Works for muted text
$gray-lighter:          #f2f2f2; // Working for footer on thumbnail panels
$gray-lightest:         #f9f9f9;
$white:                 #ffffff;
$white-smoke:           #efefef;
$davy-grey:             #e0e0e0;
$darkgreen:             #006400;

$main-gray:             #E5E5E5;
$bg1:                   #E5E5E5; // Main gray for backgrounds
$bg1-contrast:          #F5F5F5; // Works next to the main bg gray -- for table headers, panel headers, etc.
$bg1-contrast-alt:      lighten($bg1-contrast, 2%); // A slightly different version of the contrasting color, for cases where two things butt up against each other (e.g panel heading, table column header)
$bg1-contrast-inverse:  #CCCCCC; // For times when we want a darker background against the main gray background

$accent-color:          #D581A2; // Pink
$accent-color-2:        #eea345; // Orange

$border-color-default:  #F0EFF1;
$border-color-dark:     #CCCCCC;

$exposure-public-color:   #d9534f;
$exposure-external-color: #f0ad4e;
$exposure-company-color:  #fcd141;
$exposure-internal-color: #2bc3a7;
$exposure-unknown-color:  #99D9EA;

$state-danger-text:      #D84748;
$state-danger-bg:        lighten(#D84748, 40%);

$passed: #a6c348;
$failed: #bc043b;
$warning: #f7ca52;
$setting-table-bg: #fafafa;

$verified: #008CBA;

/* --------------------------------------------------------------
  Scaffolding
  Settings for some of the most global styles.
----------------------------------------------------------------- */

/* Background color for `<body>` */
$body-bg:         #f4f5f5;

/* Global text color on `<body>`. */
$text-color:             #555;
$text-muted:        #999;
$error-block: #d84748;

/* Grid system */
$grid-gutter-width:         40px;


$modal-backdrop-opacity:      .3;

$modal-header-background-color: darken($brand-primary, 3%);


/* --------------------------------------------------------------
  Typography
  Font, line-height, and color for body text, headings, and more.
----------------------------------------------------------------- */
$base-font-family:          "Lato", sans-serif;
$font-size-base:            14px;
$font-size-large:           ceil(($font-size-base * 1.1)); // 16.5px
$font-size-xl:              ceil(($font-size-base * 1.25)); // ~19px
$font-size-mega:            ceil(($font-size-base * 2.6)); // ~40px

$font-size-h1:              floor(($font-size-base * 2.2)); // ~33px
$font-size-h2:              floor(($font-size-base * 1.5)); // ~22.5px

$font-family-sans-serif:    'Lato', sans-serif;
$nav-headings-font-family:  'Lato', sans-serif;
$headings-font-family:      $base-font-family;
$headings-color:            $gray;

$line-height-base:          1.5;

$font-weight-light:         300;
$font-weight-base:          400;
$font-weight-bold:          600;

$headings-font-weight:      $font-weight-light;


/* --------------------------------------------------------------
  Iconography
----------------------------------------------------------------- */

/* Default opacity for grayscale icons */
$icon-opacity-base:          .4;


/* --------------------------------------------------------------
  Components
  Define common padding and border radius sizes and more. Values
  based on 14px text and 1.428 line-height (~20px to start).
----------------------------------------------------------------- */

$padding-base-vertical:     8px;

/* Border radius */
/* MK: Experimenting with a slightly smaller border radius */
$border-radis-xsmall:       1px;
$border-radius-base:        4px;
$border-radius-large:       6px;
$border-radius-small:       3px;
$border-radius-huge:        15px;

/* Border color for table and cell borders. */
$table-border-color:        #F0EFF1;


/* --------------------------------------------------------------
  Tables
----------------------------------------------------------------- */

/* Padding for `<th>`s and `<td>`s. */
$table-cell-padding:            18px;


$table-condensed-cell-padding:  8px;

/* Background color used for `.table-hover`. */
$table-bg-hover:        $bg1-contrast-alt;


/* --------------------------------------------------------------
  Buttons
  For each of Bootstrap's buttons, define text, background and
  border color.
----------------------------------------------------------------- */

$btn-default-bg:                rgba(#000, 0); /* Transparent  */
$btn-default-bg-hover:          #eaeaea;

$btn-primary-bg:                $brand-primary;

$btn-default-color:             $text-color;
$btn-default-border:            rgba(#000, .2);

/* MK: New Button Subtle class  */
$btn-subtle-color:              #777;
$btn-subtle-bg:                 rgba(#000, .1);
$btn-subtle-border:             rgba(#000, 0); /* Transparent  */

/* MK: This is to help text and icons vertically align with a row of buttons  */
$button-height:                 39px;


/* --------------------------------------------------------------
  Forms
----------------------------------------------------------------- */

$input-border-focus:             #259cca;
$input-color:           $text-color;


/* --------------------------------------------------------------
  Navbar
----------------------------------------------------------------- */

// $navbar-width:                 100px;
$topnav-height:                   50px;
$nav-bg-color:                    #040f16;
$nav-hover-bg:                    #555;
$navbar-border-radius:            0px;

$navbar-padding-vertical:         0px;
$navbar-padding-horizontal:       0px;

$nav-menu-bg:                     #011017;
$nav-menu-text:                   #ccc;
$nav-menu-hover-bg:               #444;
$nav-menu-dropdown-hover-bg:      #04415C;
$nav-menu-hover-text:             #fff;

/* Inverted navbar */
$navbar-inverse-bg:             #333;
$navbar-inverse-color:          #ccc;

/* Inverted navbar links */
$navbar-inverse-link-color:     #777;
$navbar-inverse-link-hover-bg:  darken($brand-primary, 20%);
$navbar-inverse-link-active-bg: $brand-primary;


/* MK: Breakpoint -- This is setting the breakpoint for when the navbar collapses. Might need to revisit -- I don't understand this well. */
$screen-sm:                     530px;


/* --------------------------------------------------------------
  Navs
----------------------------------------------------------------- */

/* MK: Setting tab background to white. It's white by default, but it seems to be picking
up a gray from somewhere. Would be better to find the source and stop it -- this is a hack */
$nav-tabs-active-link-hover-bg: #fff;


/* --------------------------------------------------------------
  Pills
----------------------------------------------------------------- */

$nav-pills-active-link-hover-bg:            $bg1-contrast-inverse;
$nav-pills-active-link-hover-color:         #fff;

/* --------------------------------------------------------------
  Pagination
----------------------------------------------------------------- */


$pagination-color:                     $btn-subtle-color;
$pagination-bg:                        $btn-subtle-bg;
$pagination-border:                    $btn-subtle-border;

$pagination-hover-color:               $btn-subtle-color;
$pagination-hover-bg:                  #ccc; /* 10% darker than base color on hover */
$pagination-hover-border:              #ccc; /* 10% darker than base color  on hover */

//$pagination-active-color:              $btn-primary-color;
//$pagination-active-bg:                 $btn-primary-bg;
//$pagination-active-border:             $btn-primary-border;

/* --------------------------------------------------------------
  Pager
----------------------------------------------------------------- */

$pager-border-radius:                  $border-radius-base;


/* --------------------------------------------------------------
  Tooltips
----------------------------------------------------------------- */

/* Tooltip background color */
$tooltip-bg:                  $gray-darker;

/* Tooltip arrow width */
$tooltip-arrow-width:         7px;




/* --------------------------------------------------------------
  Popovers
----------------------------------------------------------------- */

// $popover-title-bg:                    transparent;


/* --------------------------------------------------------------
  Labels
----------------------------------------------------------------- */

$label-default-bg:            #ccc;


/* --------------------------------------------------------------
  Panels
----------------------------------------------------------------- */

$panel-heading-padding:         20px 30px;
$panel-heading-padding-compact: 20px 15px;
$panel-footer-padding-compact:  15px 15px;
$panel-border-radius:           $border-radius-large;
$panel-body-padding:            30px;
$panel-body-padding-compact:    15px;
$panel-default-heading-bg:      $bg1-contrast;


/* --------------------------------------------------------------
  Thumbnails
----------------------------------------------------------------- */

$thumbnail-padding:           0px;
$thumbnail-bg:                #fff;
$thumbnail-border:            0px;
$thumbnail-border-radius:     0px;
$thumbnail-caption-color:     $gray-light;



/* --------------------------------------------------------------
  Type
----------------------------------------------------------------- */

/* Horizontal line color. */
$hr-border:                   #bababa;


/* --------------------------------------------------------------
  Wells
----------------------------------------------------------------- */

$well-bg:                     $bg1-contrast-inverse;
$well-border:                 transparent;


/* --------------------------------------------------------------
  Badges
----------------------------------------------------------------- */

$badge-color:                 $gray-dark;
$badge-font-weight:           400;
$badge-bg:                    #ddd;
$badge-border-radius:         12px;


/* --------------------------------------------------------------
  Breadcrumbs
----------------------------------------------------------------- */

// $breadcrumb-padding-vertical:   0px;
// $breadcrumb-padding-horizontal: 20px;
$breadcrumb-bg:                 transparent;
// $breadcrumb-color:              #ccc;
// $breadcrumb-active-color:       #fff;
$breadcrumb-active-color:       #787878;
// $breadcrumb-separator:          "";

/* --------------------------------------------------------------
  Filters
----------------------------------------------------------------- */
$filter-background-color: $white-smoke;

/* sizing values for reuse */
$size-xlg:                       20px;
