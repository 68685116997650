.filterContainer {
  padding: 3px 16px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #eaeaea;
  display: flex; }
  .filterContainer .circleIcon {
    cursor: pointer;
    display: flex;
    align-items: center; }
    .filterContainer .circleIcon .filterImage {
      display: inline-block;
      overflow: hidden;
      border-radius: 16px;
      width: 32px;
      height: 32px; }
    .filterContainer .circleIcon .filterIcon {
      padding: 8px 10px;
      cursor: pointer;
      height: 32px;
      width: 32px;
      background-color: #1f75b2; }
  .filterContainer .addFilter {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    /* Colors/Links or Informational Blue */
    color: #1f75b2;
    padding: 16px 8px;
    display: inline-block; }
  .filterContainer .filterMenu {
    display: inline-flex;
    align-items: center;
    vertical-align: middle; }
    .filterContainer .filterMenu .dropdown-menu {
      min-width: 200px !important; }
    .filterContainer .filterMenu .noFilters {
      top: 82% !important; }
    .filterContainer .filterMenu .menuItem {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      padding: 8px 16px;
      /* or 167% */
      display: flex; }
      .filterContainer .filterMenu .menuItem label {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;
        margin: 3px 0px; }
        .filterContainer .filterMenu .menuItem label.checkBox {
          width: 100%; }
        .filterContainer .filterMenu .menuItem label input[type="checkbox"] {
          margin-right: 8px;
          width: 16px;
          height: 16px;
          margin-top: 2px; }
      .filterContainer .filterMenu .menuItem .menuItemDisabled {
        cursor: not-allowed;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        padding: 8px 16px; }
        .filterContainer .filterMenu .menuItem .menuItemDisabled:first-of-type {
          padding-top: 16px; }
        .filterContainer .filterMenu .menuItem .menuItemDisabled:last-of-type {
          padding-bottom: 16px; }
      .filterContainer .filterMenu .menuItem:hover {
        background: #eff7ff; }
  .filterContainer .filterButtonGroups {
    cursor: pointer;
    display: inline-block;
    border-radius: 16px;
    padding-top: 9px; }
    .filterContainer .filterButtonGroups .filterButton {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      margin-left: 8px;
      font-size: 11px;
      display: inline-block;
      background-color: #fafafa;
      border-radius: 16px 0px 0px 16px;
      border: 1px solid #c4c4c4;
      border-right: 0;
      padding: 7px 0px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15); }
      .filterContainer .filterButtonGroups .filterButton .removeFilter {
        cursor: pointer;
        color: #717171;
        padding: 0px 7px;
        font-size: 10px;
        position: relative;
        left: 1px;
        top: 1px; }
      .filterContainer .filterButtonGroups .filterButton .filterTitle {
        padding-right: 10px; }
    .filterContainer .filterButtonGroups .subfilterSection {
      font-size: 11px;
      display: inline-block;
      cursor: pointer; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown .dropdown-menu {
        min-width: 225px;
        max-height: 330px;
        overflow-y: scroll; }
        .filterContainer .filterButtonGroups .subfilterSection .dropdown .dropdown-menu .form-inline {
          padding: 8px; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown .titleInfo {
        min-width: 75px;
        background: #fff;
        padding: 7px 20px 7px 10px;
        border: 1px solid #c4c4c4;
        border-left-color: #c4c4c4;
        border-left-style: solid;
        border-left-width: 1px;
        border-radius: 0px 16px 16px 0px;
        border-left: 0;
        -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15); }
        .filterContainer .filterButtonGroups .subfilterSection .dropdown .titleInfo svg {
          position: relative;
          top: 2px; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown .menuArrow {
        display: flex;
        position: relative;
        bottom: 19px;
        right: 8px; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown .addFiltersNos {
        color: #1f75b2; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown .formFilter {
        padding: 8px; }
        .filterContainer .filterButtonGroups .subfilterSection .dropdown .formFilter i {
          font-size: 12px;
          padding: 8px 12px; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown .subfilterSearch {
        width: 100%; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown li {
        padding: 8px 0px 8px 16px; }
        .filterContainer .filterButtonGroups .subfilterSection .dropdown li:hover {
          background-color: #eff7ff; }
      .filterContainer .filterButtonGroups .subfilterSection .dropdown label {
        cursor: pointer;
        color: #333333;
        padding: 0px;
        margin: 3px 0px;
        font-weight: 400;
        font-size: 12px;
        width: 100%;
        display: flex; }
        .filterContainer .filterButtonGroups .subfilterSection .dropdown label input[type="checkbox"] {
          margin-right: 8px;
          width: 16px;
          height: 16px;
          margin-top: 0px; }
