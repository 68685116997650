.rightButton {
  float: right;
  padding-right: 10px; }
  .rightButton .caret {
    color: #333333 !important; }
  .rightButton .dropdownMenu {
    left: auto;
    right: 0; }

.filterButton {
  padding: 0 !important;
  border: none !important;
  border-radius: 0px !important;
  color: #006fcc !important; }
  .filterButton:hover, .filterButton:focus {
    text-decoration: underline !important;
    background-color: #fff !important; }

.menuButton {
  padding: 8px; }
  .menuButton:hover {
    background-color: #eff7ff; }

.btnSelected,
.btnSelected:hover {
  color: #333333;
  background-color: #d3e8fc !important; }
