.dataProfileSection {
  max-width: 400px;
  height: 176px; }
  .dataProfileSection .content {
    display: block !important;
    padding: 15px 28px 0; }
    .dataProfileSection .content .control-label {
      font-weight: normal; }
  .dataProfileSection .selectSubtext {
    font-size: 10px;
    color: #707070; }
  .dataProfileSection .selectField {
    margin-bottom: 30px; }
    .dataProfileSection .selectField option {
      padding: 8px 0px 8px 16px; }
  .dataProfileSection .descriptionText {
    font-style: italic;
    font-weight: 400;
    margin-bottom: 20px; }
  .dataProfileSection .warning-message {
    margin-bottom: 30px;
    padding: 10px;
    background: #fff2f2;
    border-radius: 4px;
    display: flex; }
    .dataProfileSection .warning-message .warning-message-content {
      flex: 1 auto; }
      .dataProfileSection .warning-message .warning-message-content .warningBlockedAction {
        width: 12px;
        height: 12px;
        margin-right: 10px; }
  .dataProfileSection .selectField {
    margin-bottom: 0; }
  .dataProfileSection .select-form-field {
    position: relative; }
    .dataProfileSection .select-form-field img.warningBlockedAction {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 36px;
      right: 22px; }
