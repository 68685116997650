.advancedSaasVisibilityRuleListTable .controlRow {
  padding: 8px 4px; }
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(div.form-group) {
    width: 200px;
    vertical-align: middle !important; }
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(input.form-control),
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(input.form-control:focus) {
    box-shadow: none;
    color: #4a4a4a; }
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(.right-inner-addon) {
    position: relative; }
    .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(.right-inner-addon) i {
      padding: 4px 10px;
      color: #686b6f;
      opacity: 1;
      position: absolute;
      right: 0px; }
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol input[type='text'] {
    border: 1px solid #e5e5e5;
    width: 100%; }
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(div.btn-group) svg {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle; }
  .advancedSaasVisibilityRuleListTable .controlRow .searchCol :global(div.btn-group) span {
    font-size: 12px;
    padding: 0px; }

.advancedSaasVisibilityRuleListTable :global(.ag-theme-balham .ag-cell) {
  line-height: 48px; }

.advancedSaasVisibilityRuleListTable :global(.ag-row) :global(.deleteButton),
.advancedSaasVisibilityRuleListTable :global(.ag-row) :global(.editButton),
.advancedSaasVisibilityRuleListTable :global(.ag-row) :global(.cloneButton) {
  display: none; }

.advancedSaasVisibilityRuleListTable :global(.ag-row):global(.ag-row-hover) {
  z-index: 1; }
  .advancedSaasVisibilityRuleListTable :global(.ag-row):global(.ag-row-hover) :global(.deleteButton),
  .advancedSaasVisibilityRuleListTable :global(.ag-row):global(.ag-row-hover) :global(.editButton),
  .advancedSaasVisibilityRuleListTable :global(.ag-row):global(.ag-row-hover) :global(.cloneButton) {
    display: inline-block; }
