.prismaSelect {
  display: inline-block;
  font-size: 12px;
  color: #1f75b2;
  width: 70px;
  height: 24px;
  margin: 0px 5px;
  background: linear-gradient(180deg, #ffffff 0.89%, #fafafa 100.89%);
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer; }
  .prismaSelect .arrow {
    padding-top: 2px; }
  .prismaSelect .prismaSelectMenu {
    text-align: center;
    min-width: 69px !important;
    left: -10px;
    margin-bottom: 8px !important; }
    .prismaSelect .prismaSelectMenu.dropdown-menu {
      margin-bottom: 8px; }
    .prismaSelect .prismaSelectMenu .prismaSelectItem {
      cursor: pointer;
      color: #333333;
      padding: 8px;
      font-size: 12px; }
      .prismaSelect .prismaSelectMenu .prismaSelectItem:hover {
        background-color: #eff7ff; }
      .prismaSelect .prismaSelectMenu .prismaSelectItem.selectedItem {
        background-color: #d3e8fc !important; }
