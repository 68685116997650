.userForm :global(.nameField) {
  width: 282px;
  margin-right: 12px; }

.userForm :global(label) {
  font-size: 16px;
  font-weight: 400;
  color: #707070; }

.userForm :global(.form-button-row) {
  margin-top: 40px; }
  .userForm :global(.form-button-row) :global(.btn) {
    float: left;
    margin-right: 5px; }
