.app-catalog-page {
  background-color: #ffffff; }
  .app-catalog-page table > thead > tr > th {
    height: 32px; }

.disabledSection {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  opacity: 0.4;
  pointer-events: auto; }

.header {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background: #ffffff; }
  .header .title {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    padding-top: 5px;
    display: flex; }
    .header .title .beta-tag {
      padding: 4px 11px;
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      background-color: #28c2a6;
      font-weight: bold;
      border-radius: 16px;
      align-items: center;
      margin: 8px; }
  .header .desc {
    color: #707070;
    font-size: 12px; }
  .header .total-records {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */
    color: #666666; }
  .header .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    padding-bottom: 11px; }
  .header .asv-nav {
    padding-bottom: 10px; }

.table-tools-container {
  height: 50px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-bottom: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px; }
  .table-tools-container .search-box {
    position: relative; }
    .table-tools-container .search-box input {
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      width: 300px;
      padding: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px; }
    .table-tools-container .search-box .search-icon {
      position: absolute;
      top: 7px;
      right: 10px; }
  .table-tools-container div {
    margin: 5px; }
    .table-tools-container div.download-table, .table-tools-container div.settings-table {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .table-tools-container div.action-button {
    font-size: 12px;
    cursor: pointer;
    color: #006fcc;
    padding: 5px 10px;
    margin-left: 7px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: #fff; }

.label-container {
  font-size: 12px; }
