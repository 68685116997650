.userEdit {
  padding: 0;
  margin: 0; }
  .userEdit :global(.page-header) {
    border-bottom: 0px;
    font-size: 24px;
    padding: 16px 16px 16px 16px;
    margin: 0px -20px 20px -20px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333333; }
    .userEdit :global(.page-header) :global(h1) {
      margin-top: 0;
      margin-bottom: .25em;
      color: #333333;
      font-size: 30px; }
    .userEdit :global(.page-header) :global(.subhead) {
      margin-top: 6px;
      color: #707070;
      font-size: 12px; }
  .userEdit :global(dl) {
    padding: 0;
    margin: 0; }
    .userEdit :global(dl) :global(dt) {
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      padding-top: 3px;
      width: 190px;
      color: #999;
      padding-bottom: 10px; }
    .userEdit :global(dl) :global(dd) {
      padding-bottom: 10px;
      padding-top: 0px;
      margin-bottom: 0;
      margin-left: 180px;
      color: #555; }
  .userEdit :global(.panel) {
    border-radius: 1px; }
  .userEdit :global(.form-button-row) {
    margin-top: 40px; }
