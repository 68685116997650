.add-button {
  float: right; }

.title {
  margin-top: 9px; }

.search-box {
  margin-top: 20px; }

.spinner {
  height: 20px;
  margin-right: 6px;
  vertical-align: middle; }

.btnBlock {
  display: block;
  width: 100%; }

.rightInnerAddon {
  position: relative; }

.noBorderRadius {
  border-radius: 0; }

.panelHeading {
  background-color: #ffffff !important;
  font-size: 16px;
  padding: 12px 16px; }

.title {
  line-height: 1.1;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  margin-top: 0 !important;
  margin-bottom: .25em !important; }

.subhead {
  color: #707070;
  font-size: 12px; }

.tableList {
  padding: 0px !important;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); }

.dropdownAnchor {
  margin: 0 !important;
  padding: 0 !important; }

.dropdownLabel {
  margin: 0 !important;
  padding: 3px 12px 3px 12px !important;
  height: 100%;
  cursor: pointer;
  font-weight: 400; }

.dropdownInput {
  padding: 0 !important;
  position: relative !important;
  margin: 0 7px 0 0 !important; }

.userListAgGrid :global(.ag-header) {
  border-bottom: 1px solid #d4d4d4;
  background-color: #fff;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 15px !important;
  text-transform: none; }

.userListAgGrid :global(.ag-header-cell::after) {
  height: 70% !important; }

.userListAgGrid :global(.ag-header-cell) {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center; }

.userListAgGrid :global(.ag-header-cell-text) {
  overflow: visible; }

.userListAgGrid :global(.ag-header-cell-label) {
  overflow: visible; }

.userListAgGrid :global(.ag-icon-checkbox-checked) {
  color: #1f75b2; }

.userListAgGrid :global(.ag-icon-checkbox-unchecked) {
  color: #a3a3a3;
  line-height: 18px; }

.userListAgGrid :global(.ag-root) {
  border-color: #d4d4d4 !important;
  border-style: solid !important;
  border-width: 1px 0px !important;
  overflow: visible !important; }

.userListAgGrid :global(.ag-body-viewport) {
  max-height: 638px;
  overflow-y: scroll !important;
  overflow-x: hidden !important; }

.userListAgGrid :global(.ag-center-cols-container) {
  overflow: hidden; }

.userListAgGrid :global(.AgGridLoading) {
  display: flex;
  height: 4px; }

.userListAgGrid :global(.ag-header-cell) {
  background-color: #fff !important; }

.userListAgGrid :global(.ag-header-cell-text) {
  flex: 1;
  color: #333333; }

.userListAgGrid :global(.ag-cell) {
  cursor: default !important;
  color: #333333 !important;
  overflow: visible;
  display: flex;
  align-items: center; }

.userListAgGrid :global(.ag-row) {
  border: 0 !important;
  z-index: 0;
  font-size: 14px !important; }

.userListAgGrid :global(.ag-row .ag-row-focus) {
  z-index: 1; }

.userListAgGrid :global(.ag-row-odd) {
  background-color: #fafafa !important; }

.userListAgGrid :global(.ag-row-even) {
  background-color: #fff !important; }

.userListAgGrid :global(.ag-row-hover),
.userListAgGrid :global(.ag-row:hover) {
  background-color: rgba(132, 201, 255, 0.1) !important; }

.userListAgGrid :global(.ag-body-horizontal-scroll),
.userListAgGrid :global(.ag-body-horizontal-scroll-container) {
  overflow-x: none !important;
  display: none !important; }
