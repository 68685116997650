.trash {
  height: 17px; }

.maxReportReached {
  background-color: #fcefef;
  border-color: #fcefef;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 17px; }

.reportDescription {
  margin-bottom: 20px; }

.panelHeading {
  background-color: #ffffff !important;
  font-size: 16px;
  padding: 12px 16px !important; }

.dropdownMenu {
  min-width: 100%;
  text-align: left; }

.fileIcon {
  font-weight: normal;
  font-style: normal;
  opacity: 0.5;
  width: 48px;
  height: 48px; }

.executiveReportTableList {
  padding: 0px !important;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); }
  .executiveReportTableList .agThemeBalham :global(.ag-header), .executiveReportTableList .ag-theme-balham :global(.ag-header) {
    border-bottom: 1px solid #d4d4d4;
    background-color: #fff;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    font-size: 15px !important;
    line-height: 15px !important;
    text-transform: none; }
  .executiveReportTableList .agThemeBalham :global(.ag-header-cell::after), .executiveReportTableList .ag-theme-balham :global(.ag-header-cell::after) {
    height: 70%; }
  .executiveReportTableList .agThemeBalham :global(.ag-header-cell), .executiveReportTableList .ag-theme-balham :global(.ag-header-cell) {
    display: flex;
    align-items: center; }
  .executiveReportTableList .agThemeBalham :global(.ag-header-cell-text), .executiveReportTableList .ag-theme-balham :global(.ag-header-cell-text) {
    overflow: visible; }
  .executiveReportTableList .agThemeBalham :global(.ag-header-cell-label), .executiveReportTableList .ag-theme-balham :global(.ag-header-cell-label) {
    overflow: visible; }
  .executiveReportTableList .agThemeBalham :global(.ag-icon-checkbox-checked), .executiveReportTableList .ag-theme-balham :global(.ag-icon-checkbox-checked) {
    color: #1f75b2; }
  .executiveReportTableList .agThemeBalham :global(.ag-icon-checkbox-unchecked), .executiveReportTableList .ag-theme-balham :global(.ag-icon-checkbox-unchecked) {
    color: #a3a3a3;
    line-height: 18px; }
  .executiveReportTableList :global(.ag-root) {
    border-color: #d4d4d4 !important;
    border-style: solid !important;
    border-width: 1px 0px !important;
    overflow: visible !important; }
  .executiveReportTableList :global(.ag-body-viewport) {
    max-height: 638px;
    overflow-y: scroll !important;
    overflow-x: hidden !important; }
  .executiveReportTableList :global(.ag-center-cols-container) {
    overflow: hidden; }
  .executiveReportTableList :global(.AgGridLoading) {
    display: flex;
    height: 4px; }
  .executiveReportTableList :global(.ag-header-cell) {
    background-color: #fff !important; }
  .executiveReportTableList :global(.ag-header-cell-text) {
    flex: 1;
    color: #333333; }
  .executiveReportTableList :global(.ag-cell) {
    cursor: default !important;
    color: #333333 !important;
    overflow: visible;
    display: flex;
    align-items: center; }
  .executiveReportTableList :global(.ag-row) {
    border: 0 !important;
    z-index: 0;
    font-size: 14px !important; }
  .executiveReportTableList :global(.ag-row .ag-row-focus) {
    z-index: 1; }
  .executiveReportTableList :global(.ag-row-odd) {
    background-color: #fafafa !important; }
  .executiveReportTableList :global(.ag-row-even) {
    background-color: #fff !important; }
  .executiveReportTableList :global(.ag-row-hover),
  .executiveReportTableList :global(.ag-row:hover) {
    background-color: rgba(132, 201, 255, 0.1) !important; }
  .executiveReportTableList :global(.ag-body-horizontal-scroll),
  .executiveReportTableList :global(.ag-body-horizontal-scroll-container) {
    overflow-x: none !important;
    display: none !important; }

.saasInlineSecurityReport {
  margin-top: 20px; }
  .saasInlineSecurityReport :global(h2) {
    margin-top: 0;
    margin-bottom: .25em;
    line-height: 1.1;
    font-size: 24px;
    font-weight: 400;
    color: #333333; }
  .saasInlineSecurityReport :global(.panel) {
    border-radius: 0; }
